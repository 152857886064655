import React, { useEffect, useMemo, useCallback, useState, useContext } from "react";

import COMMON from "common";
import api from "services/api";
import classNames from "common/class-names";
import serveRequestErrors from "common/serve-request-errors";
import { AxiosContext } from "contexts/with-interceptor-provider";
import { formatDatePattern, CALENDAR_FORMAT, addDays, findToday, getISOString } from "common/calendar";
import AppButton from "components/app-button";
import AppChevronIcon from "components/icons/app-chevron-icon";
import listingIcon from "assets/images/pages/leave-management/listing-icon.svg";

const currentDateFormat = CALENDAR_FORMAT.MONTH_FORMAT + " " + CALENDAR_FORMAT.YEAR_FORMAT;
const dateFormat = CALENDAR_FORMAT.MONTH_FORMAT + ", " + CALENDAR_FORMAT.WEEKDAYS_LONG_FORMAT;

const AppListCalendar = (props) => {
	const context = useContext(AxiosContext);
	const [holiday, setHoliday] = useState([]);
	const [date, setDate] = useState(new Date());
	const [calendar, setCalendar] = useState({});

	const dates = useMemo(() => {
		const dateArray = [];
		const startDate = new Date(date.getFullYear(), date.getMonth(), 1);
		const lastDate = new Date(date.getFullYear(), date.getMonth() + 1, 0);
		let currentDate = startDate;

		while (currentDate <= lastDate) {
			const obj = { date: new Date(currentDate).getDate(), day: formatDatePattern(currentDate, dateFormat), d: getISOString(currentDate), today: findToday(new Date(currentDate)), disabled: false };
			dateArray.push(obj);
			currentDate = addDays(currentDate, 1);
		}

		return dateArray;
	}, [date]);

	const onHandleChangeDate = (event) => {
		const action = event.target.getAttribute("data-target");
		if (action === "data-prev") setDate(new Date(date.setMonth(date.getMonth() - 1)));
		if (action === "data-next") setDate(new Date(date.setMonth(date.getMonth() + 1)));
	};

	const onHandleSetToday = () => {
		setDate(new Date());
	};

	//prettier-ignore
	const sanitizeCalendar = useCallback((response) => {
		const leaves = {};

		dates.forEach((a) => {
			response.forEach((b) => {
				const isSameDate = getISOString(b.date) === a.d;

				if (isSameDate) {
					const date = leaves[a.d];
					const leaveType = b.leaveTypeMapped;
					const timeOffType = b.timeOffType;

					if (date) {
						if (date[leaveType]) {
							if (date[leaveType][timeOffType]) {
								const timeOffTypes = date[leaveType][timeOffType];
								leaves[a.d][leaveType][timeOffType] = [...timeOffTypes, b];
							} else {
								leaves[a.d][leaveType] = {
									...date[leaveType],
									[timeOffType]: [b],
								};
							}
						} else {
							leaves[a.d] = {
								...date,
								[leaveType]: { [timeOffType]: [b] },
							};
						}
					} else {
						leaves[a.d] = {};
						leaves[a.d][leaveType] = {};
						leaves[a.d][leaveType][timeOffType] = [b];
					}
				}
			});
		});

		setCalendar(leaves);
	}, [dates]);

	const onHandleGetList = useCallback(async () => {
		let response = null;
		let holidayResponse = null;

		try {
			const payload = { month: date.getMonth() + 1, year: date.getFullYear() };
			response = await api.get.leaves.calendar(payload);
			holidayResponse = await api.get.general.holiday(date.getFullYear());
		} catch (error) {
			serveRequestErrors(error);
		}

		if (response) sanitizeCalendar(response);
		if (holidayResponse) setHoliday(holidayResponse);
	}, [date, sanitizeCalendar]);

	useEffect(() => {
		onHandleGetList();
	}, [onHandleGetList]);

	useEffect(() => {
		return () => {
			context.onHandleCancelRequest(COMMON.ENDPOINT_PATH.LEAVES.CALENDAR);
			context.onHandleCancelRequest(COMMON.ENDPOINT_PATH.GENERAL.HOLIDAY);
		};
	}, [context]);

	return (
		<div className="app-list-calendar">
			<div className="list-calendar">
				<div className="list-calendar__calendar">
					<div className="leaves-calendar">
						<div className="leaves-calendar__header">
							<button type="button" className="leaves-calendar__button" onClick={onHandleSetToday}>
								Today
							</button>

							<div className="leaves-calendar__navigation">
								<p className="leaves-calendar__current">{formatDatePattern(date, currentDateFormat)}</p>
								<button type="button" className="leaves-calendar__button leaves-calendar__button--prev" data-target="data-prev" onClick={onHandleChangeDate}>
									<AppChevronIcon color="#0245a9" />
								</button>
								<button type="button" className="leaves-calendar__button leaves-calendar__button--next" data-target="data-next" onClick={onHandleChangeDate}>
									<AppChevronIcon color="#0245a9" />
								</button>
							</div>

							<AppButton type="button" outline label="Calendar view" icon={listingIcon} onClick={() => props.onLayoutChange(props.type)} />
						</div>

						<div className="leaves-calendar__body">
							<ul className="leaves-calendar__list">
								{dates.map((a) => {
									const dateClassName = classNames({ "leaves-calendar__date": true, "leaves-calendar__date--today": a.today });
									const daysClassName = classNames({ "leaves-calendar__text": true, "leaves-calendar__text--today": a.today });
									const holidayDate = holiday.filter((o) => getISOString(o.date) === a.d)[0];
									const holidayEvent = holidayDate ? `(${holidayDate.title})` : "";

									return (
										<li className="leaves-calendar__item" key={a.date}>
											<div className="leaves-calendar__dates">
												<p className={dateClassName}>{a.date}</p>
												<p className={daysClassName}>
													{a.day} <span className="leaves-calendar__holiday">{holidayEvent}</span>
													{holidayDate?.states && <span className="leaves-calendar__states">{holidayDate.states}</span>}
												</p>
											</div>

											<div className="leaves-calendar__wrapper">
												{Object.keys(calendar).map((date, i) => {
													const noEventDay = !calendar[a.d];

													if (noEventDay && i === 0) {
														return (
															<div className="leaves-calendar__candidates" key={date}>
																<p className="leaves-calendar__day">No event today</p>
																<div className="leaves-calendar__candidate" />
															</div>
														);
													} else if (a.d === date) {
														const leaves = calendar[date];

														return Object.keys(leaves).map((leaveType) => {
															const leave = leaves[leaveType];

															return Object.keys(leave).map((timeOffType) => {
																return (
																	<div className="leaves-calendar__candidates" key={timeOffType}>
																		<p className="leaves-calendar__day">
																			{leaveType}, {timeOffType}
																		</p>

																		<div className="leaves-calendar__candidate">
																			{leave[timeOffType].map((e) => {
																				return (
																					<div className="leaves-calendar__applier" key={e.id}>
																						<div className="leaves-calendar__profile" style={{ backgroundImage: `url(${e.userAvatar})` }} />
																						<p className="leaves-calendar__label">{e.userFullName}</p>
																					</div>
																				);
																			})}
																		</div>
																	</div>
																);
															});
														});
													} else {
														return null;
													}
												})}
											</div>
										</li>
									);
								})}
							</ul>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export default AppListCalendar;
