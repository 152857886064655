import React, { useRef, useCallback, useMemo, useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import Menu from "@mui/material/Menu";

import COMMON from "common";
import api from "services/api";
import sanitizeObject from "common/sanitize-object";
import { formatDatePattern } from "common/calendar";
import serveRequestErrors from "common/serve-request-errors";
import AppEmptyState from "components/app-empty-state";
import AppLeaveModal from "components/pages/leave-management/app-leave-modal";
import AppTable, { AppTableEditIcon, AppTableCell } from "components/app-table";
import previewIcon from "assets/images/preview-icon.svg";
import exclamationIcon from "assets/images/exclamation-icon.svg";
import pdfIcon from "assets/images/pages/leave-management/pdf-icon.svg";

const PageLeavesPending = (props) => {
	const leaveModalRef = useRef();
	const [searchParams, setSearchParams] = useSearchParams();
	const memoSetSearchParams = useRef(setSearchParams);
	//prettier-ignore
	const paramsRef = useRef({
		page: parseInt(searchParams.get("page")) || 1,
		sort: searchParams.get("sort") || "",
	});
	const [anchorEl, setAnchorEl] = useState(null);
	const [selectedItem, setSelectedItem] = useState({});
	const [data, setData] = useState({ page: paramsRef.current.page, size: 10, total: 0, offset: 0, totalElements: 0, prev: false, next: false, items: [] });
	const isEmptyState = useMemo(() => COMMON.TABLE_REQUEST_STATUS.INSTANCE === data.status && !paramsRef.current.statusListStr, [data.status]);
	const cancelRequest = useMemo(() => props.onHandleCancelRequest, [props.onHandleCancelRequest]);

	const onHandleGetList = useCallback(async () => {
		let response = null;

		setData({ page: paramsRef.current.page, size: 10, total: 0, offset: 0, totalElements: 0, prev: false, next: false, items: [] });

		try {
			const payload = { ...paramsRef.current, size: 10 };

			memoSetSearchParams.current(sanitizeObject(payload), { replace: true });

			payload.page = paramsRef.current.page - 1;

			response = await api.get.leaves.list(sanitizeObject({ ...payload, statusListStr: "PENDING" }));
		} catch (error) {
			serveRequestErrors(error);
		}

		if (response) {
			setData((prev) => ({
				...prev,
				page: paramsRef.current.page,
				prev: !response.first,
				next: !response.last,
				items: response.content,
				total: response.totalPages,
				totalElements: response.totalElements,
				offset: response?.pageable?.offset || 0,
				status: response?.pageable,
			}));
		}
	}, []);

	const onHandleTableMenu = useCallback((event, original) => {
		const types = {};

		original.companyLeaveApplicationDetails.forEach((o) => {
			if (types[o.leaveType]) {
				types[o.leaveType] = { ...types[o.leaveType], noOfDays: types[o.leaveType].noOfDays + 1 };
			} else {
				types[o.leaveType] = {};
				types[o.leaveType] = { ...o, leaveTypeLabel: o.leaveType === COMMON.BENEFITS.LEAVES.CARRY_FORWARD_LEAVE ? "Carry Forward" : original.leaveType };
			}

			return types;
		});

		setSelectedItem(types);
		setAnchorEl(event.currentTarget);
	}, []);

	const onhandleCloseMenu = () => {
		setAnchorEl(null);
	};

	//prettier-ignore
	const onHandlePagination = useCallback((event) => {
		const control = event.currentTarget?.getAttribute("data-ctrl");

		if (control) {
			if (control === "prev") {
				if (paramsRef.current.page <= 1) return;
				paramsRef.current.page -= 1;
			} else {
				if (paramsRef.current.page >= data.total) return;
				paramsRef.current.page += 1;
			}
		} else {
			paramsRef.current.page = event.target.value;
		}

		onHandleGetList();
	}, [onHandleGetList, data.total]);

	const onHandleViews = useCallback((id) => {
		leaveModalRef.current.onHandleShow(id);
	}, []);

	//prettier-ignore
	const MenuViewCell = useCallback(({ row }) => {
		return <AppTableEditIcon icon={previewIcon} onClick={() => onHandleViews(row.original.id)} />; 
	}, [onHandleViews]);

	//prettier-ignore
	const MenuCell = useCallback(({ row }) => {
		const isMixedCarryForward = row.original.companyLeaveApplicationDetails.findIndex(o => o.leaveType === COMMON.BENEFITS.LEAVES.CARRY_FORWARD_LEAVE) > -1;
		if(isMixedCarryForward) return <AppTableEditIcon className="table__exclamation" icon={exclamationIcon} onClick={(event) => onHandleTableMenu(event, row.original)} />;
		else return null;
	}, [onHandleTableMenu]);

	const StartDateCell = useCallback(({ row }) => {
		return <AppTableCell left value={formatDatePattern(row.original.companyLeaveApplicationDetails[0].date) || "-"} />;
	}, []);

	const EndDateCell = useCallback(({ row }) => {
		return <AppTableCell left value={formatDatePattern(row?.original?.companyLeaveApplicationDetails?.[row?.original?.companyLeaveApplicationDetails?.length - 1]?.date) || "-"} />;
	}, []);

	//prettier-ignore
	const AttachmentCell = useCallback(({ row }) => {
		const file = row.original?.companyLeaveApplicationFiles?.[0];
		const value = file ? (<a href={file?.fileURL} target="_blank" rel="noopener noreferrer"><img className="table__file" src={pdfIcon} alt="attacment" /></a>) : ("-");
		return (
			<AppTableCell center value={value} />
		);
	}, []);

	//prettier-ignore
	const columns = useMemo(() => [
		{
			Header: "Leave Type",
			accessor: "leaveType",
			disableSortBy: true,
		},
		{
			Header: "Days",
			accessor: "noOfDays",
			disableSortBy: true,
		},
		{
			Header: "Start Date",
			id: "startDate",
			accessor: "companyLeaveApplicationDetails",
			disableSortBy: true,
            Cell: StartDateCell
		},
		{
			Header: "End Date",
			id: "endDate",
			accessor: "companyLeaveApplicationDetails",
			disableSortBy: true,
            Cell: EndDateCell
		},
		{
            Header: "Time Off",
			accessor: "timeOff.label",
			disableSortBy: true,
		},
		{
            Header: () => <div className="table__attachment">Attachment</div>,
			accessor: "companyLeaveApplicationFiles",
			disableSortBy: true,
            Cell: AttachmentCell
		},
		{
			Header: "",
			accessor: "*",
			disableSortBy: true,
			Cell: MenuCell,
		},
		{
			Header: "",
			accessor: "**",
			disableSortBy: true,
			Cell: MenuViewCell,
		},
	], [MenuCell, MenuViewCell, StartDateCell, EndDateCell, AttachmentCell]);

	useEffect(() => {
		onHandleGetList();
	}, [onHandleGetList]);

	useEffect(() => {
		return () => {
			cancelRequest(COMMON.ENDPOINT_PATH.LEAVES.LIST);
		};
	}, [cancelRequest]);

	return (
		<div className="page-leaves-pending">
			<div className="leaves-pending">
				{!isEmptyState && <AppTable columns={columns} pages={data} onHandlePagination={onHandlePagination} />}
				{isEmptyState && <AppEmptyState title="No records found" description="You don’t have any records yet" disabledButton />}
			</div>

			{/* prettier-ignore */}
			<Menu classes={{ root: "app-table-menu" }} anchorEl={anchorEl} open={!!anchorEl} onClose={onhandleCloseMenu} anchorOrigin={{ vertical: "bottom", horizontal: "right" }} transformOrigin={{ vertical: "top", horizontal: "right" }}>
				<p className="app-table-menu__title">Leave Deduction:</p>

				{Object.keys(selectedItem).map((o, i) => {
					const item = selectedItem[o];
					return (
						<div className="app-table-menu__item" key={i}>
							<p className="app-table-menu__text">• {item.leaveTypeLabel} Leave</p>
							<p className="app-table-menu__title">{item.noOfDays}</p>
						</div>
					);
				})}
			</Menu>

			<AppLeaveModal ref={leaveModalRef} />
		</div>
	);
};

export default PageLeavesPending;
