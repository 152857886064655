import React, { useCallback, useMemo, useRef, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { useSelector } from "react-redux";

import AppTabs from "components/app-tabs";
import AppEmployeeInfoTab from "components/pages/profile/app-employee-info-tab";
import AppPersonalInfoTab from "components/pages/profile/app-personal-info-tab";

const PageProfile = (props) => {
	const profile = useSelector((state) => state.profile);
	const [searchParams, setSearchParams] = useSearchParams();
	//prettier-ignore
	const tabsNav = useMemo(() => [
		{ label: "Personal Info", value: "Personal Info" },
		{ label: "Employee Info", value: "Employee Info" },
	], []);
	const tabParams = useRef(searchParams.get("tab") || tabsNav[0].value);
	const memoSetSearchParams = useRef(setSearchParams);
	const [tab, setTab] = useState(tabParams.current);

	const Tabs = useCallback(() => {
		switch (tab) {
			case "Employee Info":
				return <AppEmployeeInfoTab />;
			case "Personal Info":
				return <AppPersonalInfoTab />;
			default:
				return null;
		}
	}, [tab]);

	const onHandleChangeTab = useCallback((v) => {
		setTab(v);
		memoSetSearchParams.current({ tab: v }, { replace: true });
	}, []);

	return (
		<div className="page-profile">
			<div className="profile">
				<h1 className="profile__name">{profile?.fullName}</h1>

				<AppTabs tabs={tabsNav} value={tab} onChange={onHandleChangeTab} />

				<div className="profile__container">
					<Tabs />
				</div>
			</div>
		</div>
	);
};

export default PageProfile;
