import React, { memo, forwardRef, useImperativeHandle, useMemo, useState, useCallback, useRef } from "react";
import { useDispatch } from "react-redux";
import Modal from "@mui/material/Modal";
import { useFormik } from "formik";
import PropTypes from "prop-types";
import * as yup from "yup";

import api from "services/api";
import ERRORS from "common/errors";
import { promptAlertMessage } from "store/slices/alert";
import { CALENDAR_FORMAT } from "common/calendar";
import validateFileSize from "common/validate-file-size";
import serveRequestErrors from "common/serve-request-errors";
import validateClaimBalance from "common/validate-claim-balance";
import formatCurrencyPattern from "common/format-currency-pattern";
import getClaimTypesListing from "services/get-claim-types-listing";
import converReadableFileSize from "common/convert-readable-file-size";
import AppInput from "components/app-input";
import AppButton from "components/app-button";
import AppSelectInput from "components/app-select-input";
import AppCalendarInput from "components/app-calendar-input";
import uploadIcon from "assets/images/upload-icon.svg";

const calendarDisplayFormat = CALENDAR_FORMAT.DATE_FORMAT + " " + CALENDAR_FORMAT.MONTH_FORMAT + " " + CALENDAR_FORMAT.YEAR_FORMAT;

export const AppApplyClaimModal = (props, ref) => {
	const dispatch = useDispatch();
	const uploadInputRef = useRef();
	const currentYear = useMemo(() => new Date().getFullYear(), []);
	const minDate = useMemo(() => new Date(currentYear, 0, 1), [currentYear]);
	const maxDate = useMemo(() => new Date(currentYear, 11, 31), [currentYear]);
	const [visible, setVisible] = useState(false);
	const initialValues = useMemo(() => ({ claimType: "", amount: "", visitDate: "", description: "", file: "" }), []);
	//prettier-ignore
	const formik = useFormik({
		initialValues,
		validationSchema: yup.object({
			amount: yup.string().required(ERRORS.REQUIRED).test("insufficientBalance", ERRORS.INSUFFICIENT_BALANCE, function () { return validateClaimBalance(this.parent.claimType, props.claimConfig); }),
			claimType: yup.string().required(ERRORS.REQUIRED),
			visitDate: yup.string().required(ERRORS.REQUIRED),
			file: yup.mixed().nullable().test("fileSize", ERRORS.FILE_SIZE, validateFileSize),
		}),
		onSubmit: (values) => {
			onHandleConfirm(values);
		},
	});

	const fileSize = useMemo(() => formik.values?.file?.size, [formik.values]);

	const onHandleShow = useCallback(() => {
		setVisible(true);
	}, []);

	const onHandleDismiss = useCallback(() => {
		setVisible(false);
	}, []);

	const onHandleUploadFile = useCallback(() => {
		uploadInputRef.current.click();
	}, []);

	//prettier-ignore
	const onHandleChange = useCallback((event) => {
		const file = event.target.files[0];
		formik.setFieldValue("file", file);
	}, [formik]);

	const onHandleKeyDown = (event) => {
		const cursorPosition = event.target.selectionEnd;
		const value = event.target.value;

		if (cursorPosition !== value.length) {
			event.target.selectionEnd = value.length;
			event.target.selectionStart = value.length;
			event.preventDefault();
		}

		if (event.key.length === 1 && !event.key.match(/\d/i)) {
			event.preventDefault();
		}
	};

	//prettier-ignore
	const onHandleUploadDocument = useCallback(async (values, leaveId) => {
		let response = false;

		if (values.file) {
			try {
				const formData = new FormData();
				formData.append("files", values.file);
				await api.post.claims.uploadDocument(leaveId, formData);
				response = true;
			} catch (error) {
				serveRequestErrors(error);
				formik.setSubmitting(false);
			}
		}
		else {
			response = true;
		}

		if (response) {
			setVisible(false);
			formik.resetForm();
			dispatch(promptAlertMessage({ message: "Claim has been submitted successfully" }));
			props.onHandleGetSummary();
		}
	}, [dispatch, formik, props]);

	//prettier-ignore
	const onHandleConfirm = useCallback(async (values) => {
		let response = false;

		try {
			const payload = {
				claimType: values.claimType,
				claimValue: parseFloat(values.amount.replace(/,/g,'')),
				actualDate: values.visitDate,
				description: values.description,
			};
			response = await api.post.claims.create(payload);
		} catch (error) {
			serveRequestErrors(error);
			formik.setSubmitting(false);
		}
		if (response) onHandleUploadDocument(values, response?.id);
	}, [formik, onHandleUploadDocument]);

	//prettier-ignore
	useImperativeHandle(ref, () => ({
		onHandleShow: onHandleShow,
		onHandleDismiss: onHandleDismiss,
	}));

	return (
		<Modal classes={{ root: "app-apply-claim-modal" }} open={visible} onClose={onHandleDismiss} aria-labelledby="apply-claim-modal" aria-describedby="apply-claim-modal">
			<div className="apply-claim-modal">
				<div className="apply-claim-modal__main">
					<div className="apply-claim-modal__header">
						<p className="apply-claim-modal__title">New Claim</p>
					</div>

					<form className="apply-claim-modal__body" onSubmit={formik.handleSubmit}>
						{/* prettier-ignore */}
						<AppSelectInput required type="text" name="claimType" label="Claim Type" placeholder="Please Select" loadOptions={getClaimTypesListing} value={formik.values.claimType} error={formik.errors.claimType} touched={formik.touched.claimType} disabled={formik.isSubmitting} onChange={formik.handleChange} />

						{/* prettier-ignore */}
						<AppInput required type="text" name="amount" placeholder="Enter Amount" label="Bill Amount (RM)" value={formik.values.amount} error={formik.errors.amount} touched={formik.touched.amount} disabled={formik.isSubmitting} onChange={formik.handleChange} onKeyDown={onHandleKeyDown} onFormat={formatCurrencyPattern} maxLength={12}/>

						{/* prettier-ignore */}
						<AppCalendarInput required name="visitDate" label="Visit Date" placeholder="01 Jan 2023" displayFormat={calendarDisplayFormat} minDate={minDate} maxDate={maxDate} value={formik.values.visitDate} error={formik.errors.visitDate} touched={formik.touched.visitDate} disabled={formik.isSubmitting} onChange={formik.setFieldValue} />

						{/* prettier-ignore */}
						<AppInput multiline multilineRow={4} maxLength={255} type="text" name="description" label="Description" placeholder="Description" value={formik.values.description} error={formik.errors.description} touched={formik.touched.description} disabled={formik.isSubmitting} onChange={formik.handleChange} />

						<div className="apply-claim-modal__upload-input">
							{/* prettier-ignore */}
							<label className="app-input__label" htmlFor="attachment">Attachment</label>
							<p className="apply-claim-modal__description">Please ensure that your file is in the correct format (jpg, pdf) with each file size not exceeding 5MB.</p>

							<div className="apply-claim-modal__input">
								{formik.values?.file && (
									<button type="button" className="apply-claim-modal__upload-button" onClick={onHandleUploadFile}>
										{formik.values?.file?.name}
										<img className="apply-claim-modal__icon" src={uploadIcon} alt="upload" />
									</button>
								)}

								{!formik.values?.file && (
									<button type="button" className="apply-claim-modal__upload-button" onClick={onHandleUploadFile}>
										Uploaded
										<img className="apply-claim-modal__icon" src={uploadIcon} alt="upload" />
									</button>
								)}

								<p className="apply-claim-modal__label">{converReadableFileSize(fileSize, true) || "-"}</p>

								<input type="file" name="file" accept="image/png, image/jpeg" hidden ref={uploadInputRef} onChange={onHandleChange} />
							</div>

							{formik.touched.file && formik.errors.file && <div className="apply-claim-modal__error">{formik.errors.file}</div>}
						</div>

						<div className="apply-claim-modal__button-container">
							<AppButton type="button" label="Cancel" outline onClick={onHandleDismiss} />
							<AppButton type="submit" label="Create" />
						</div>
					</form>
				</div>
			</div>
		</Modal>
	);
};

export default memo(forwardRef(AppApplyClaimModal));

AppApplyClaimModal.propTypes = {
	ref: PropTypes.object,
};
