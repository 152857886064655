import React, { useEffect, useMemo, useRef, useCallback, useState, useContext } from "react";
import MenuItem from "@mui/material/MenuItem";
import Menu from "@mui/material/Menu";
import moment from "moment";

import COMMON from "common";
import api from "services/api";
import classNames from "common/class-names";
import serveRequestErrors from "common/serve-request-errors";
import { AxiosContext } from "contexts/with-interceptor-provider";
import { formatDatePattern, CALENDAR_FORMAT, getISOString, getDates, isSameDate, nthDate, getWeekdays } from "common/calendar";
import AppButton from "components/app-button";
import AppChevronIcon from "components/icons/app-chevron-icon";
import listingIcon from "assets/images/pages/leave-management/listing-icon.svg";

const currentDateFormat = CALENDAR_FORMAT.MONTH_FORMAT + " " + CALENDAR_FORMAT.YEAR_FORMAT;

const AppGridCalendar = (props) => {
	const originalLeaves = useRef();
	const context = useContext(AxiosContext);
	const [holiday, setHoliday] = useState([]);
	const [date, setDate] = useState(new Date());
	const [menuItems, setMenuItems] = useState(null);
	const [onLeaves, setOnLeaves] = useState([]);
	const [calendar, setCalendar] = useState({});
	const [anchorEl, setAnchorEl] = useState(null);
	const dates = useMemo(() => getDates(date), [date]);
	const weekdays = useMemo(() => getWeekdays(CALENDAR_FORMAT.WEEKDAYS_FORMAT), []);

	const onHandleChangeDate = (event) => {
		const action = event.target.getAttribute("data-target");
		if (action === "data-prev") setDate(new Date(date.setMonth(date.getMonth() - 1)));
		if (action === "data-next") setDate(new Date(date.setMonth(date.getMonth() + 1)));
	};

	const onHandleSetToday = () => {
		setDate(new Date());
	};

	const onhandleCloseMenu = useCallback(() => {
		setAnchorEl(null);
		setMenuItems(null);
	}, []);

	//prettier-ignore
	const onHandleShowEmployeeAppliedLeave = useCallback((event, employeeLeaveApplied) => {
        /* TO BE Enhance */

        const selectedDate = event.currentTarget.getAttribute("data-type");

        event.stopPropagation();

        if (employeeLeaveApplied) {
            const targetedItems = Object.keys(calendar)
                .map((o) => (isSameDate(o, selectedDate) ? calendar[o] : null))
                .filter(Boolean);

            let flattenItems = Object.keys(targetedItems).map((a) => targetedItems[a]);
            /* Flatten Leave Types */
            flattenItems = flattenItems.map((type) => Object.keys(type).map((a) => type[a])).flat();
            /* Flatten Leave hours */
            flattenItems = flattenItems.map((hour) => Object.keys(hour).map((a) => hour[a]).flat()).flat();

            const users = flattenItems.map((item) => item.userEmail);

            const flattenUsers = [...new Set(users)];

            const leaves = {};

            /* Set all leaves ranges */
            flattenUsers.forEach((user) => {
                originalLeaves.current.forEach((o) => {
                    if (!leaves[user]) leaves[user] = {};

                    if (!leaves[user][o.leaveType]) leaves[user][o.leaveType] = {};

                    if (!leaves[user][o.leaveType][o.timeOffType]) leaves[user][o.leaveType][o.timeOffType] = [];

                    leaves[user][o.leaveType][o.timeOffType].push(o);
                });

                Object.keys(leaves[user]).forEach((leaveType) => {
                    Object.keys(leaves[user][leaveType]).forEach((timeOffType) => {
                        let userLeaves = leaves[user][leaveType][timeOffType];

                        const ranges = userLeaves
                            .sort((a, b) => new Date(a.date).getTime() - new Date(b.date).getTime())
                            .reduce((acc, obj) => {
                                const group = acc[acc.length - 1];

                                if (moment(obj.date).diff(moment(group[group.length - 1]?.date || obj.date), "days") > 1) {
                                    acc.push([obj]);
                                } else {
                                    group.push(obj);
                                }

                                return acc;
                            }, [[]]);

                        leaves[user][leaveType][timeOffType] = leaves[user][leaveType][timeOffType].map((o) => ({ ...o, ranges }));
                    });
                });
            });

            /* Set specify selected date ranges */
            flattenItems = flattenItems.map((a) => {
                let items = { ...a };
                const userEmail = a.userEmail;

                Object.keys(leaves[userEmail]).forEach((b) => {
                    if (leaves[userEmail][b][a.timeOffType]) {
                        const sameDate = leaves[userEmail][b][a.timeOffType].find((c) => c.date === a.date);

                        const ranges = sameDate?.ranges.filter(arr => arr.findIndex(o => o.date === a.date) > -1);
                        
                        if (sameDate) items.ranges = ranges[0];
                    }
                });

                return items;
            });

            setOnLeaves(flattenItems);
        }
    }, [calendar]);

	//prettier-ignore
	const onHandleOpenMenu = useCallback((event, holidayEvent) => {
        event.stopPropagation();

		if (holidayEvent) {
			const hoverDate = event.currentTarget.getAttribute("data-type");
            const targetedItems = holiday.map(o => isSameDate(o.date, hoverDate) ? o : null).filter(Boolean);
			setMenuItems(targetedItems);
			setAnchorEl(event.currentTarget);
		}

	}, [holiday]);

	//prettier-ignore
	const sanitizeCalendar = useCallback((response) => {
		const leaves = {};

		dates.forEach((a) => {
			response.forEach((b) => {
				const isSame = isSameDate(b.date, a.iso);

				if (isSame) {
					const date = leaves[a.iso];
					const leaveType = b.leaveTypeMapped;
					const timeOffType = b.timeOffType;

					if (date) {
						if (date[leaveType]) {
							if (date[leaveType][timeOffType]) {
								const timeOffTypes = date[leaveType][timeOffType];
								leaves[a.iso][leaveType][timeOffType] = [...timeOffTypes, b];
							} else {
								leaves[a.iso][leaveType] = {
									...date[leaveType],
									[timeOffType]: [b],
								};
							}
						} else {
							leaves[a.iso] = {
								...date,
								[leaveType]: { [timeOffType]: [b] },
							};
						}
					} else {
						leaves[a.iso] = {};
						leaves[a.iso][leaveType] = {};
						leaves[a.iso][leaveType][timeOffType] = [b];
					}
				}
			});
		});

		setCalendar(leaves);
	}, [dates]);

	const onHandleGetList = useCallback(async () => {
		let response = null;
		let holidayResponse = null;

		try {
			const payload = { month: date.getMonth() + 1, year: date.getFullYear() };
			response = await api.get.leaves.calendar(payload);
			holidayResponse = await api.get.general.holiday(date.getFullYear());
		} catch (error) {
			serveRequestErrors(error);
		}

		if (response) {
			originalLeaves.current = response;
			sanitizeCalendar(response);
		}
		if (holidayResponse) setHoliday(holidayResponse);
	}, [date, sanitizeCalendar]);

	useEffect(() => {
		onHandleGetList();
	}, [onHandleGetList]);

	useEffect(() => {
		return () => {
			context.onHandleCancelRequest(COMMON.ENDPOINT_PATH.LEAVES.CALENDAR);
			context.onHandleCancelRequest(COMMON.ENDPOINT_PATH.GENERAL.HOLIDAY);
		};
	}, [context]);

	//prettier-ignore
	const getEmployeeLeaveApplied = useCallback((iso) => {
		return Object.keys(calendar).findIndex((date) => iso === date) > -1;
	}, [calendar]);

	return (
		<div className="app-grid-calendar">
			<div className="grid-calendar">
				<div className="grid-calendar__calendar">
					<div className="leaves-calendar">
						<div className="leaves-calendar__header">
							<button type="button" className="leaves-calendar__button" onClick={onHandleSetToday}>
								Today
							</button>

							<div className="leaves-calendar__navigation">
								<p className="leaves-calendar__current">{formatDatePattern(date, currentDateFormat)}</p>
								<button type="button" className="leaves-calendar__button leaves-calendar__button--prev" data-target="data-prev" onClick={onHandleChangeDate}>
									<AppChevronIcon color="#0245a9" />
								</button>
								<button type="button" className="leaves-calendar__button leaves-calendar__button--next" data-target="data-next" onClick={onHandleChangeDate}>
									<AppChevronIcon color="#0245a9" />
								</button>
							</div>

							<AppButton type="button" outline label="Listing View" icon={listingIcon} onClick={() => props.onLayoutChange(props.type)} />
						</div>

						<div className="leaves-calendar__body">
							<div className="leaves-calendar__calendar-grid">
								<ul className="leaves-calendar__weekdays">
									{weekdays.map((o) => (
										<li key={o.day} className="leaves-calendar__weekday-item">
											<div className="leaves-calendar__weekday">{o.day}</div>
										</li>
									))}
								</ul>

								<ul className="leaves-calendar__list">
									{dates.map((a) => {
										const employeeLeaveApplied = getEmployeeLeaveApplied(a.iso);
										const dateClassName = classNames({ "leaves-calendar__date": true, "leaves-calendar__date--leave": employeeLeaveApplied, "leaves-calendar__date--today": a.isToday && !employeeLeaveApplied });
										const daysClassName = classNames({
											"leaves-calendar__holiday-event": true,
											"leaves-calendar__text": true,
											"leaves-calendar__text--leave": employeeLeaveApplied,
											"leaves-calendar__text--today": a.isToday && !employeeLeaveApplied,
										});
										const holidayDate = holiday.filter((o) => getISOString(o.date) === a.iso)[0];

										const holidayEvent = holidayDate ? `(${holidayDate.title})` : "";

										const onMouseEnter = (event) => onHandleOpenMenu(event, holidayEvent);

										const onClick = (event) => onHandleShowEmployeeAppliedLeave(event, employeeLeaveApplied);

										return (
											<li className="leaves-calendar__item" key={a.iso} data-type={a.iso} onMouseEnter={onMouseEnter} onClick={onClick}>
												<div className="leaves-calendar__dates">
													<p className={dateClassName}>{a.date}</p>

													{holidayEvent && (
														<p className={daysClassName}>
															<span className="leaves-calendar__holiday">
																{holidayEvent} {holidayDate?.states || ""}
															</span>
														</p>
													)}
												</div>
											</li>
										);
									})}
								</ul>
							</div>

							<ul className="leaves-calendar__leaves">
								{onLeaves.map((leave, i) => {
									const profileStyles = { backgroundImage: `url(${leave.userAvatar})` };

									let dates = () => {
										const firstDate = leave.ranges[0]?.date;
										if (leave.ranges.length > 1) {
											const lastDate = leave.ranges[leave.ranges.length - 1].date;
											return `${new Date(firstDate).getDate()}${nthDate(new Date(firstDate).getDate())} - ${new Date(lastDate).getDate()}${nthDate(new Date(lastDate).getDate())}`;
										} else {
											return `${new Date(leave.ranges[0].date).getDate()}${nthDate(new Date(leave.ranges[0].date).getDate())}`;
										}
									};

									return (
										<li className="leaves-calendar__on-leave" key={i}>
											<div className="leaves-calendar__profile" style={profileStyles} />
											<p className="leaves-calendar__on-leave-name">
												{leave.userFullName}
												<br />
												<span className="leaves-calendar__on-leave-type">{leave.leaveTypeMapped}</span>
											</p>
											<p className="leaves-calendar__on-leave-date">{dates()}</p>
										</li>
									);
								})}
							</ul>
						</div>
					</div>
				</div>
			</div>

			{/* prettier-ignore */}
			<Menu classes={{ root: "app-header-menu app-leaves-menu" }} anchorEl={anchorEl} open={!!anchorEl} MenuListProps={{ onMouseLeave: onhandleCloseMenu }} onClose={onhandleCloseMenu} anchorOrigin={{ vertical: "top", horizontal: "right" }} transformOrigin={{ vertical: "top", horizontal: "right" }}>
				{menuItems?.map((o) => {
					return (
						<MenuItem key={o.id}>
							<div className="holiday-event"><p className="holiday-event__text">{o.title},<br/><span className="holiday-event__state">{o.states}</span></p></div>
						</MenuItem>
					);
				})}
			</Menu>
		</div>
	);
};

export default AppGridCalendar;
