import COMMON from "common";

const validateFileSize = (files) => {
	if (files?.length) {
		const uploadedFiles = files;

		const valid = uploadedFiles.some((o) => (!o.size ? true : o.size <= COMMON.MAX_FILE_SIZE));
		return valid;
	} else if (files?.size) {
		return files?.size <= COMMON.MAX_FILE_SIZE;
	} else {
		return true;
	}
};

export default validateFileSize;
