import React, { memo, forwardRef, useImperativeHandle, useState, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import Modal from "@mui/material/Modal";
import PropTypes from "prop-types";

import COMMON from "common";
import api from "services/api";
import pathnames from "routes/pathnames";
import { setCompany } from "store/slices/profile";
import getCompanyListing from "services/get-company-listing";
import serveRequestErrors from "common/serve-request-errors";
import AppButton from "components/app-button";
import AppSelectInput from "components/app-select-input";

export const AppSelectCompanyModal = (props, ref) => {
	const profile = useSelector((state) => state.profile);
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const [options, setOptions] = useState([]);
	const [visible, setVisible] = useState(false);
	const [isSubmiting, setIsSubmiting] = useState(false);
	const [selectCompany, setSelectCompany] = useState(profile.company);

	//prettier-ignore
	const onHandleComfirm = useCallback(async (company) => {
		let response = null;
		const nextCompany = company || selectCompany;
		props.loading.current?.onHandleShow();
		setIsSubmiting(true);
		
		try {
			await api.post.authenticate.user(nextCompany);
			response = true;
		} catch (error) {
			setIsSubmiting(false);
			serveRequestErrors(error);
		}

		//Delay for Backend need to know the which company selected
		if (response) { 
			setVisible(false);
			setTimeout(() => dispatch(setCompany(nextCompany)), 1000);
		}
	}, [props, selectCompany, dispatch]);

	const onHandleShow = useCallback(async () => {
		let response = null;

		try {
			response = await getCompanyListing();
		} catch (error) {
			console.log(error);
		}

		if (response?.content) setOptions(response.content);

		if (response?.content.length > 1) {
			setVisible(true);
		} else {
			props.loading.current?.onHandleShow();
			const company = response?.content?.[0]?.value;

			if (company) {
				setVisible(false);
				setSelectCompany(company);
				onHandleComfirm(company);
			}
		}
	}, [props, onHandleComfirm]);

	const onHandleDismiss = useCallback(() => {
		if (!profile.company) {
			setVisible(false);
			localStorage.clear();
			sessionStorage.clear();
			dispatch({ type: COMMON.REDUX_ACTION.LOGOUT });
			navigate(pathnames.login);
		} else {
			setVisible(false);
		}
	}, [profile, dispatch, navigate]);

	const onHandleChange = useCallback((event) => {
		const value = event.target.value;
		setSelectCompany(value);
	}, []);

	//prettier-ignore
	useImperativeHandle(ref, () => ({
		onHandleShow: onHandleShow,
		onHandleDismiss: onHandleDismiss,
	}));

	return (
		<Modal classes={{ root: "app-select-company" }} open={visible} aria-labelledby="select-company" aria-describedby="select-company-modal">
			<div className="select-company">
				<div className="select-company__header">
					<p className="select-company__title">Select a Company to access</p>
					<p className="select-company__description">Please select your company from the list below. This will ensure that you have access to the relevant resources and information specific to your organization.</p>
				</div>

				<AppSelectInput required type="text" name="company" label="Company" placeholder="Please Select" value={selectCompany} options={options} onChange={onHandleChange} />

				<div className="select-company__button-container">
					<AppButton type="button" label="Cancel" outline onClick={onHandleDismiss} />
					<AppButton type="button" label="Confirm" onClick={() => onHandleComfirm()} disabled={isSubmiting || !selectCompany} />
				</div>
			</div>
		</Modal>
	);
};

export default memo(forwardRef(AppSelectCompanyModal));

AppSelectCompanyModal.propTypes = {
	ref: PropTypes.object,
};
