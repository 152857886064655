import React, { Fragment, forwardRef, useRef, useEffect, useState, useMemo } from "react";
import { useSelector } from "react-redux";

import COMMON from "common";
import AppScreenLoading from "components/app-screen-loading";
import AppSelectCompanyModal from "components/app-select-company";

const withCompanies = (WrappedComponent) => {
	return forwardRef(({ ...props }, ref) => {
		const loadingRef = useRef();
		const onLoadTimeout = useRef();
		const selectCompanyRef = useRef();
		const profile = useSelector((state) => state.profile);
		const isApp = useMemo(() => sessionStorage.getItem(COMMON.EVENTS.APP), []);
		const onHandleGetProfile = useMemo(() => props.onHandleGetProfile, [props]);
		const onHandleCancelProfile = useMemo(() => props.onHandleCancelProfile, [props]);
		const [initialized, setInitalized] = useState(false);

		useEffect(() => {
			const localToken = localStorage.getItem(COMMON.AUTH_TOKEN);
			const sessionToken = sessionStorage.getItem(COMMON.AUTH_TOKEN);
			const token = localToken || sessionToken;

			if (!profile.company && !isApp && token) {
				onLoadTimeout.current = setTimeout(() => {
					selectCompanyRef.current.onHandleShow();
				}, 1000);
			} else if (token) {
				loadingRef.current.onHandleShow();
				onLoadTimeout.current = setTimeout(() => {
					onHandleGetProfile(() => {
						setInitalized(true);
						loadingRef.current.onHandleDismiss();
					});
				}, 1000);
			}

			return () => {
				clearTimeout(onLoadTimeout.current);
				onHandleCancelProfile(COMMON.ENDPOINT_PATH.GENERAL.PROFILE);
			};
		}, [isApp, profile?.company, onHandleCancelProfile, onHandleGetProfile]);

		return (
			<Fragment>
				<AppScreenLoading ref={loadingRef} />

				<WrappedComponent ref={ref} {...props} initialized={initialized} />

				<AppSelectCompanyModal ref={selectCompanyRef} loading={loadingRef} />
			</Fragment>
		);
	});
};
export default withCompanies;
