import React, { useMemo, useContext, useEffect, useCallback } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import { useFormik } from "formik";
import * as yup from "yup";

import COMMON from "common";
import api from "services/api";
import ERRORS from "common/errors";
import { promptAlertMessage } from "store/slices/alert";
import serveRequestErrors from "common/serve-request-errors";
import getJobLevelListing from "services/get-job-level-listing";
import { AxiosContext } from "contexts/with-interceptor-provider";
import { CALENDAR_FORMAT } from "common/calendar";
import getOrganisationListing from "services/get-organisation-listing";
import getContractTypeListing from "services/get-contract-type-listing";
import getEmploymentEndStatusListing from "services/get-employment-end-status-listing";
import AppInput from "components/app-input";
import AppButton from "components/app-button";
import AppSelectInput from "components/app-select-input";
import AppCalendarInput from "components/app-calendar-input";

const calendarDisplayFormat = CALENDAR_FORMAT.DATE_FORMAT + " " + CALENDAR_FORMAT.MONTH_FORMAT + " " + CALENDAR_FORMAT.YEAR_FORMAT;

const AppEmployeeInfoTab = () => {
	const { id } = useParams();
	const navigate = useNavigate();
	const dispatch = useDispatch();
	const context = useContext(AxiosContext);
	//prettier-ignore
	const initialValues = useMemo(() => ({ organisation: "", employeeId: "", workEmail: "", position: "", level: "", contractType: "", dateJoined: "", probationEndDate: "", lastPromotionDate: "", lastEmploymentDate: "", employmentEndStatus: "", internshipEndDate:"", reportingManager: "", benefit: "", insurancePlan: "" }), []);
	const formik = useFormik({
		initialValues,
		validationSchema: yup.object({
			organisation: yup.string().required(ERRORS.REQUIRED),
			employeeId: yup.string().required(ERRORS.REQUIRED),
			workEmail: yup.string().matches(COMMON.REGEX.EMAIL, ERRORS.EMAIL).required(ERRORS.REQUIRED),
			position: yup.string().required(ERRORS.REQUIRED),
			level: yup.string().required(ERRORS.REQUIRED),
			contractType: yup.string().required(ERRORS.REQUIRED),
			dateJoined: yup.string().required(ERRORS.REQUIRED),
			probationEndDate: yup.string().when(["contractType"], {
				is: (contractType) => contractType !== COMMON.STATUS_ID.INTERN,
				then: () => yup.string().required(ERRORS.REQUIRED),
			}),
			employmentEndStatus: yup.string().when(["date"], {
				is: (date) => !!date,
				then: () => yup.string().required(ERRORS.REQUIRED),
			}),
			internshipEndDate: yup.string().when(["contractType"], {
				is: (contractType) => contractType === COMMON.STATUS_ID.INTERN,
				then: () => yup.string().required(ERRORS.REQUIRED),
			}),
		}),
		onSubmit: (values) => {
			onHandleSubmit(values);
		},
	});
	const lastEmploymentDate = useMemo(() => formik.values.lastEmploymentDate, [formik.values.lastEmploymentDate]);
	const intershipContractType = useMemo(() => formik.values.contractType === COMMON.STATUS_ID.INTERN, [formik.values.contractType]);
	const memoSetValues = useMemo(() => formik.setValues, [formik]);

	const onHandleSubmit = async (values) => {
		let response = null;

		try {
			let payload = {
				userId: id,
				level: values.level,
				position: values.position,
				workEmail: values.workEmail,
				dateJoined: values.dateJoined,
				employeeId: values.employeeId,
				benefitPackageId: values.benefit,
				contractType: values.contractType,
				employmentStatus: values.employmentEndStatus,
				businessRegistrationNo: values.organisation,
				reportingManagerId: values.reportingManager,
				ebInsurancePlan: values.insurancePlan,
				probationEndDate: "",
				lastPromotionDate: "",
				lastEmploymentDate: "",
				internshipEndDate: "",
			};

			if (intershipContractType) payload.internshipEndDate = values.internshipEndDate;

			if (values.lastEmploymentDate) {
				payload.employmentStatus = values.employmentEndStatus;
			} else if (!values.lastEmploymentDate && !values.employmentEndStatus) {
				payload.employmentStatus = COMMON.STATUS_ID.PROBATION;
			}

			if (!intershipContractType) {
				payload.probationEndDate = values.probationEndDate;
				payload.lastPromotionDate = values.lastPromotionDate;
				payload.lastEmploymentDate = values.lastEmploymentDate;
			}

			await api.post.employee.updateEmployeeInfo(payload);

			response = true;
		} catch (error) {
			serveRequestErrors(error);
		} finally {
			formik.setSubmitting(false);
		}

		if (response) {
			dispatch(promptAlertMessage({ message: "Employee has been update successfully" }));
		}
	};

	const onHandleCancel = () => {
		navigate(-1);
	};

	//prettier-ignore
	const onHandleLastEmployementDate = useCallback((name, value) => {
		formik.setFieldValue(name, value);
		formik.setFieldValue("employmentEndStatus", "");
	}, [formik]);

	//prettier-ignore
	const onHandleContractType = useCallback((event) => {
		const value = event.target.value;
		const name = event.target.name;
		formik.setFieldValue(name, value);
		formik.setFieldValue("probationEndDate", "");
		formik.setFieldValue("lastEmploymentDate", "");
		
		if(value === COMMON.STATUS_ID.INTERN) {
			formik.setFieldValue("employmentEndStatus", COMMON.STATUS_ID.INTERN);
		}
		else {
			formik.setFieldValue("employmentEndStatus", COMMON.STATUS_ID.PROBATION);
		}
	}, [formik]);

	useEffect(() => {
		const onHandleGetDetails = async () => {
			let response = null;

			try {
				response = await api.get.employee.employeeInfo();
			} catch (error) {
				serveRequestErrors(error);
			}

			if (response) {
				memoSetValues({
					organisation: response.orgBusinessRegistrationNo || "",
					employeeId: response.employeeId || "",
					workEmail: response.workEmail || "",
					position: response.position || "",
					level: response.level || "",
					contractType: response.contractType || "",
					dateJoined: response.dateJoined || "",
					probationEndDate: response.probationEndDate || "",
					lastPromotionDate: response.lastPromotionDate || "",
					lastEmploymentDate: response.lastEmploymentDate || "",
					reportingManager: response.reportingManagerName || "",
					benefit: response.benefitPackageName || "",
					insurancePlan: response.ebInsurancePlan || "",
					internshipEndDate: response.internshipEndDate || "",
					employmentEndStatus: response.employmentStatus || "",
				});
			}
		};

		onHandleGetDetails();
	}, [memoSetValues]);

	useEffect(() => {
		return () => {
			context.onHandleCancelRequest(COMMON.ENDPOINT_PATH.EMPLOYEE.EMPLOYEE_INFO);
		};
	}, [context]);

	return (
		<div className="app-employee-info-tab">
			<form className="employee-info" onSubmit={formik.handleSubmit}>
				<div className="employee-info__box">
					<div className="employee-info__form">
						{/* prettier-ignore */}
						<AppInput required disabled type="text" name="employeeId" label="Employee ID" placeholder="Please Enter Employee ID" value={formik.values.employeeId} error={formik.errors.employeeId} touched={formik.touched.employeeId} onChange={formik.handleChange} />

						{/* prettier-ignore */}
						<AppSelectInput required disabled type="text" name="organisation" label="Organisation" placeholder="Please Select" loadOptions={getOrganisationListing} value={formik.values.organisation} error={formik.errors.organisation} touched={formik.touched.organisation} onChange={formik.handleChange} />

						{/* prettier-ignore */}
						<AppInput required disabled type="text" name="workEmail" label="Work Email" placeholder="Work Email" value={formik.values.workEmail} error={formik.errors.workEmail} touched={formik.touched.workEmail} onChange={formik.handleChange} />

						{/* prettier-ignore */}
						<AppInput required disabled type="text" name="position" label="Position" placeholder="Position" value={formik.values.position} error={formik.errors.position} touched={formik.touched.position} onChange={formik.handleChange} />

						{/* prettier-ignore */}
						<AppSelectInput required disabled type="text" name="level" label="Level" placeholder="Please Select" loadOptions={getJobLevelListing} value={formik.values.level} error={formik.errors.level} touched={formik.touched.level} onChange={formik.handleChange} />

						{/* prettier-ignore */}
						<AppSelectInput required disabled type="text" name="contractType" label="Contract Type" placeholder="Please Select" loadOptions={getContractTypeListing} value={formik.values.contractType} error={formik.errors.contractType} touched={formik.touched.contractType} onChange={onHandleContractType} />

						{/* prettier-ignore */}
						<AppCalendarInput required disabled name="dateJoined" label="Date Joined" placeholder="01 Jan 2023" displayFormat={calendarDisplayFormat} value={formik.values.dateJoined} error={formik.errors.dateJoined} touched={formik.touched.dateJoined} onChange={formik.setFieldValue} />

						{
							/* prettier-ignore */ !intershipContractType &&<AppCalendarInput required disabled name="probationEndDate" label="Probation End Date" placeholder="01 Jan 2023" displayFormat={calendarDisplayFormat} value={formik.values.probationEndDate} error={formik.errors.probationEndDate} touched={formik.touched.probationEndDate} onChange={formik.setFieldValue} />
						}

						{
							/* prettier-ignore */ !intershipContractType && <AppCalendarInput disabled name="lastPromotionDate" label="Last Promotion Date" placeholder="01 Jan 2023" displayFormat={calendarDisplayFormat} value={formik.values.lastPromotionDate} error={formik.errors.lastPromotionDate} touched={formik.touched.lastPromotionDate} onChange={formik.setFieldValue} />
						}

						{
							/* prettier-ignore */ !intershipContractType && <AppCalendarInput disabled name="lastEmploymentDate" label="Last Employment Date" placeholder="01 Jan 2023" displayFormat={calendarDisplayFormat} value={formik.values.lastEmploymentDate} error={formik.errors.lastEmploymentDate} touched={formik.touched.lastEmploymentDate} onChange={onHandleLastEmployementDate} />
						}

						{
							/* prettier-ignore */ intershipContractType && <AppCalendarInput disabled name="internshipEndDate" label="Internship End Date" placeholder="01 Jan 2023" displayFormat={calendarDisplayFormat} value={formik.values.internshipEndDate} error={formik.errors.internshipEndDate} touched={formik.touched.internshipEndDate} onChange={formik.setFieldValue} />
						}

						{
							/* prettier-ignore */ lastEmploymentDate && !intershipContractType && <AppSelectInput disabled type="text" name="employmentEndStatus" label="Employment End Status" placeholder="Please Select" loadOptions={getEmploymentEndStatusListing} value={formik.values.employmentEndStatus} error={formik.errors.employmentEndStatus} touched={formik.touched.employmentEndStatus} onChange={formik.handleChange} />
						}

						{/* prettier-ignore */}
						<AppInput disabled type="text" name="reportingManager" label="Reporting Manager" placeholder="Reporting Manager" value={formik.values.reportingManager} error={formik.errors.reportingManager} touched={formik.touched.reportingManager} onChange={formik.handleChange} />
					</div>
				</div>

				<div className="employee-info__box">
					<div className="employee-info__header">
						<p className="employee-info__title">Benefits Enrollment</p>
					</div>

					<div className="employee-info__form">
						{/* prettier-ignore */}
						<AppInput required disabled type="text" name="benefit" label="Benefit Plan" placeholder="Benefit Plan" value={formik.values.benefit} error={formik.errors.benefit} touched={formik.touched.benefit} onChange={formik.handleChange} />

						{/* prettier-ignore */}
						<AppSelectInput disabled type="text" name="insurancePlan" label="Insurance Plan" placeholder="Please Select" value={formik.values.insurancePlan} error={formik.errors.insurancePlan} touched={formik.touched.insurancePlan} onChange={formik.handleChange} />
					</div>
				</div>

				<div className="employee-info__button-container">
					<AppButton type="button" label="Cancel" outline onClick={onHandleCancel} />
					<AppButton type="submit" label="Save" disabled />
				</div>
			</form>
		</div>
	);
};

export default AppEmployeeInfoTab;
