import COMMON from "common";
import pathnames from "routes/pathnames";
import AppLayout from "components/app-layout";
import { publicRoutes, protectedRoutes } from "common/auth-routes";

import Page404 from "pages/page-404";
import PageApp from "pages/page-app";
import PageLogin from "pages/page-login";
import PageProfile from "pages/page-profile";
import PageDashboard from "pages/page-dashboard";
import PageMaintenance from "pages/page-maintenance";
import PageLeaves from "pages/leave-management/page-leaves";
import PageLeavesHistory from "pages/leave-management/page-leaves-history";
import PageLeavesSummary from "pages/leave-management/page-leaves-summary";
import PageLeavesPending from "pages/leave-management/page-leaves-pending";
import PageLeavesUpcoming from "pages/leave-management/page-leaves-upcoming";
import PageClaimSummary from "pages/claim-management/page-claim-summary";
import PageClaimHistory from "pages/claim-management/page-claim-history";
import PageClaimPending from "pages/claim-management/page-claim-pending";

const token = localStorage.getItem(COMMON.AUTH_TOKEN) || sessionStorage.getItem(COMMON.AUTH_TOKEN);

const routes = [
	{ path: pathnames.login, element: <PageLogin />, authenticated: false },
	{ path: pathnames.dashboard, element: <PageDashboard />, authenticated: true },
	{ path: pathnames.profile, element: <PageProfile />, authenticated: true },
	{ path: pathnames.leaves, element: <PageLeaves />, authenticated: true },
	{ path: pathnames.leavesUpcoming, element: <PageLeavesUpcoming />, authenticated: true },
	{ path: pathnames.leavesHistory, element: <PageLeavesHistory />, authenticated: true },
	{ path: pathnames.leavesSummary, element: <PageLeavesSummary />, authenticated: true },
	{ path: pathnames.leavesPending, element: <PageLeavesPending />, authenticated: true },
	{ path: pathnames.claimSummary, element: <PageClaimSummary />, authenticated: true },
	{ path: pathnames.claimHistory, element: <PageClaimHistory />, authenticated: true },
	{ path: pathnames.claimPending, element: <PageClaimPending />, authenticated: true },
	{ path: pathnames.app, element: <PageApp />, authenticated: false },
	{ path: pathnames.maintenance, element: <PageMaintenance />, authenticated: false },
	{ path: "*", element: <Page404 />, authenticated: !!token },
];

const enrichedRouters = routes.map(({ authenticated, ...res }) => {
	if (!authenticated) return { ...res, loader: publicRoutes, errorElement: <Page404 /> };

	return { ...res, element: <AppLayout>{res.element}</AppLayout>, loader: protectedRoutes, errorElement: <Page404 /> };
});

export default enrichedRouters;
