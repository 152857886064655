import React, { memo, useMemo } from "react";
import PropTypes from "prop-types";

import COMMON from "common";
import classNames from "common/class-names";
import capitalizeCharacter from "common/capitalize-character";

const AppStatus = (props) => {
	const red = useMemo(() => {
		switch (props.status) {
			case COMMON.STATUS_ID.EXPIRED:
			case COMMON.STATUS_ID.DELETED:
			case COMMON.STATUS_ID.INACTIVE:
			case COMMON.STATUS_ID.ON_LEAVE:
			case COMMON.STATUS_ID.REJECTED:
			case COMMON.STATUS_ID.RESIGNED:
			case COMMON.STATUS_ID.DISMISSAL:
			case COMMON.STATUS_ID.CANCELLED:
			case COMMON.STATUS_ID.TERMINATION:
			case COMMON.STATUS_ID.CONVERSATION_ENDED:
				return true;
			default:
				return false;
		}
	}, [props.status]);

	const green = useMemo(() => {
		switch (props.status) {
			case COMMON.STATUS_ID.ACTIVE:
			case COMMON.STATUS_ID.WORKING:
			case COMMON.STATUS_ID.APPROVED:
			case COMMON.STATUS_ID.ACCEPTED:
			case COMMON.STATUS_ID.PERMANENT:
				return true;
			default:
				return false;
		}
	}, [props.status]);

	const yellow = useMemo(() => {
		switch (props.status) {
			case COMMON.STATUS_ID.INTERN:
				return true;
			default:
				return false;
		}
	}, [props.status]);

	const blue = useMemo(() => {
		switch (props.status) {
			case COMMON.STATUS_ID.PENDING:
			case COMMON.STATUS_ID.PROBATION:
				return true;
			default:
				return false;
		}
	}, [props.status]);

	const className = useMemo(() => {
		return classNames({ status: true, "status--green": green, "status--red": red, "status--blue": blue, "status--yellow": yellow });
	}, [red, green, blue, yellow]);

	const status = useMemo(() => {
		const string = props.status || "";

		switch (string) {
			case COMMON.STATUS_ID.CONVERSATION_ENDED:
				return "Closed";
			default:
				return capitalizeCharacter(string.split("_").join(" "));
		}
	}, [props?.status]);

	return (
		<div className="app-status">
			<div className={className}>
				<p className="status__text">{status || "-"}</p>
			</div>
		</div>
	);
};

export default memo(AppStatus);

AppStatus.propTypes = {
	status: PropTypes.string,
};
