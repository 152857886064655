import React, { memo, forwardRef, useImperativeHandle, useState, useCallback } from "react";
import Modal from "@mui/material/Modal";
import PropTypes from "prop-types";

import AppButton from "components/app-button";

export const AppAnnouncementDescriptionModal = (props, ref) => {
	const [visible, setVisible] = useState(false);
	const [description, setDescription] = useState("");

	const onHandleShow = useCallback((text) => {
		setVisible(true);
		setDescription(text);
	}, []);

	const onHandleDismiss = useCallback(() => {
		setVisible(false);
		setDescription("");
	}, []);

	//prettier-ignore
	useImperativeHandle(ref, () => ({
		onHandleShow: onHandleShow,
		onHandleDismiss: onHandleDismiss,
	}));

	return (
		<Modal classes={{ root: "app-announcement-description-modal" }} open={visible} aria-labelledby="announcement-description" aria-describedby="announcement-description-modal">
			<div className="announcement-description">
				<p className="announcement-description__description">{description}</p>

				<div className="announcement-description__button-container">
					<AppButton type="button" label="Close" onClick={onHandleDismiss} />
				</div>
			</div>
		</Modal>
	);
};

export default memo(forwardRef(AppAnnouncementDescriptionModal));

AppAnnouncementDescriptionModal.propTypes = {
	ref: PropTypes.object,
};
