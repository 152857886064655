const formatPassportString = (event) => {
	const value = event.target.value;
	const passportRegex = /^[A-Z]\d*$/;

	let sanitizedValue = "";

	for (const char of value) {
		if ((sanitizedValue + char).length > 12) break;
		if (passportRegex.test(sanitizedValue + char)) sanitizedValue += char;
	}

	event.target.value = sanitizedValue;

	return event;
};

export default formatPassportString;
