import api from "services/api";
import serveRequestErrors from "common/serve-request-errors";

const getClaimTypesListing = async () => {
	let response = null;
	let errorMessage = null;

	try {
		const res = await api.get.claims.types();

		if (res?.companyBenefitClaims) {
			response = { content: res.companyBenefitClaims.map(({ claimTypeMapped, claimType, ...o }) => ({ ...o, label: claimTypeMapped, value: claimType })) };
		}
	} catch (error) {
		errorMessage = serveRequestErrors(error);
	}

	if (response) return response;

	throw errorMessage;
};

export default getClaimTypesListing;
