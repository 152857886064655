import React, { memo, forwardRef, useImperativeHandle, useMemo, useState, useCallback, useRef } from "react";
import { useDispatch } from "react-redux";
import Modal from "@mui/material/Modal";
import { useFormik } from "formik";
import PropTypes from "prop-types";
import * as yup from "yup";

import api from "services/api";
import ERRORS from "common/errors";
import { promptAlertMessage } from "store/slices/alert";
import validateFileSize from "common/validate-file-size";
import serveRequestErrors from "common/serve-request-errors";
import getTimeOffListing from "services/get-time-off-listing";
import validateLeaveBalance from "common/validate-leave-balance";
import getLeaveTypesListing from "services/get-leave-types-listing";
import converReadableFileSize from "common/convert-readable-file-size";
import { CALENDAR_FORMAT, isDateBeforeToday, getBetweenDates } from "common/calendar";
import AppInput from "components/app-input";
import AppButton from "components/app-button";
import AppSelectInput from "components/app-select-input";
import AppCalendarInput from "components/app-calendar-input";
import uploadIcon from "assets/images/upload-icon.svg";

const calendarDisplayFormat = CALENDAR_FORMAT.DATE_FORMAT + " " + CALENDAR_FORMAT.MONTH_FORMAT + " " + CALENDAR_FORMAT.YEAR_FORMAT;

export const AppApplyLeaveModal = (props, ref) => {
	const dispatch = useDispatch();
	const timeOffRef = useRef();
	const uploadInputRef = useRef();
	const currentYear = useMemo(() => new Date().getFullYear(), []);
	const minDate = useMemo(() => new Date(currentYear, 0, 1), [currentYear]);
	const maxDate = useMemo(() => new Date(currentYear, 11, 31), [currentYear]);
	const [visible, setVisible] = useState(false);
	const initialValues = useMemo(() => ({ leaveType: "", timeOff: "", startDate: "", endDate: "", description: "", file: "" }), []);
	//prettier-ignore
	const formik = useFormik({
		initialValues,
		validationSchema: yup.object({
			leaveType: yup.string().required(ERRORS.REQUIRED),
			timeOff: yup.string().required(ERRORS.REQUIRED),
			startDate: yup.string().required(ERRORS.REQUIRED).test("beforeEndDate", ERRORS.START_DATE, function (value) { return isDateBeforeToday(value, this.parent.endDate); }),
			endDate: yup.string().required(ERRORS.REQUIRED).test("afterStartDate", ERRORS.END_DATE, function (value) { return isDateBeforeToday(this.parent.startDate || new Date(), value); }).test("insufficientBalance", ERRORS.INSUFFICIENT_BALANCE, function (value) { return validateLeaveBalance(this.parent.leaveType, props.leaveConfig, { id: this.parent.timeOff, options: timeOffRef.current?.getOptionsData() }, this.parent.startDate, value); }),
			file: yup.mixed().nullable().test("fileSize", ERRORS.FILE_SIZE, validateFileSize),
		}),
		onSubmit: (values) => {
			onHandleConfirm(values);
		},
	});
	const fileSize = useMemo(() => formik.values?.file?.size, [formik.values]);

	const onHandleShow = useCallback(() => {
		setVisible(true);
	}, []);

	const onHandleDismiss = useCallback(() => {
		setVisible(false);
	}, []);

	const onHandleUploadFile = useCallback(() => {
		uploadInputRef.current.click();
	}, []);

	//prettier-ignore
	const onHandleChange = useCallback((event) => {
		const file = event.target.files[0];
		formik.setFieldValue("file", file);
	}, [formik]);

	//prettier-ignore
	const onHandleUploadDocument = useCallback(async (values, leaveId) => {
		let response = false;

		if (values.file) {
			try {
				const formData = new FormData();
				formData.append("files", values.file);
				await api.post.leaves.uploadDocument(leaveId, formData);
				response = true;
			} catch (error) {
				serveRequestErrors(error);
				formik.setSubmitting(false);
			}
		}
		else {
			response = true;
		}

		if (response) {
			setVisible(false);
			formik.resetForm();
			dispatch(promptAlertMessage({ message: "Leave has been submitted successfully" }));
			props.onHandleGetSummary();
		}
	}, [dispatch, formik, props]);

	//prettier-ignore
	const onHandleConfirm = useCallback(async (values) => {
		let response = false;

		const timeOffOptions = timeOffRef.current.getOptionsData();

		try {
			const companyLeaveApplicationDetails = getBetweenDates(new Date(values.startDate), new Date(values.endDate)).map((o) => {
				const timeOffValue = timeOffOptions.find(o => o.id.toString() === values.timeOff).unit;
				const leaves = ({ date: o.d, leaveType: values.leaveType, noOfDays: timeOffValue });
				return leaves;
			});

			const payload = {
				timeOffId: values.timeOff,
				leaveType: values.leaveType,
				description: values.description,
				companyLeaveApplicationDetails
			};
			response = await api.post.leaves.create(payload);
		} catch (error) {
			serveRequestErrors(error);
			formik.setSubmitting(false);
		}
		if (response) onHandleUploadDocument(values, response?.id);
	}, [formik, onHandleUploadDocument]);

	//prettier-ignore
	useImperativeHandle(ref, () => ({
		onHandleShow: onHandleShow,
		onHandleDismiss: onHandleDismiss,
	}));

	return (
		<Modal classes={{ root: "app-apply-leave-modal" }} open={visible} onClose={onHandleDismiss} aria-labelledby="apply-leave-modal" aria-describedby="apply-leave-modal">
			<div className="apply-leave-modal">
				<div className="apply-leave-modal__main">
					<div className="apply-leave-modal__header">
						<p className="apply-leave-modal__title">New Leave</p>
						<p className="apply-leave-modal__note">Note: Any remaining carry-forward leave will be deducted prior to annual leave deductions.</p>
					</div>

					<form className="apply-leave-modal__body" onSubmit={formik.handleSubmit}>
						{/* prettier-ignore */}
						<AppSelectInput required type="text" name="leaveType" label="Leave Type" placeholder="Please Select" loadOptions={getLeaveTypesListing} value={formik.values.leaveType} error={formik.errors.leaveType} touched={formik.touched.leaveType} disabled={formik.isSubmitting} onChange={formik.handleChange} />

						{/* prettier-ignore */}
						<AppSelectInput required ref={timeOffRef} type="text" name="timeOff" label="Time Off" placeholder="Please Select" loadOptions={getTimeOffListing} value={formik.values.timeOff} error={formik.errors.timeOff} touched={formik.touched.timeOff} disabled={formik.isSubmitting} onChange={formik.handleChange} />

						{/* prettier-ignore */}
						<AppCalendarInput required name="startDate" label="Start Date" placeholder="01 Jan 2023" displayFormat={calendarDisplayFormat} minDate={minDate} maxDate={maxDate} value={formik.values.startDate} error={formik.errors.startDate} touched={formik.touched.startDate} disabled={formik.isSubmitting} onChange={formik.setFieldValue} />

						{/* prettier-ignore */}
						<AppCalendarInput required name="endDate" label="End Date" placeholder="01 Jan 2023" displayFormat={calendarDisplayFormat} minDate={minDate} maxDate={maxDate} value={formik.values.endDate} error={formik.errors.endDate} touched={formik.touched.endDate} disabled={formik.isSubmitting} onChange={formik.setFieldValue} />

						{/* prettier-ignore */}
						<AppInput multiline multilineRow={4} maxLength={255} type="text" name="description" label="Description" placeholder="Description" value={formik.values.description} error={formik.errors.description} touched={formik.touched.description} disabled={formik.isSubmitting} onChange={formik.handleChange} />

						<div className="apply-leave-modal__upload-input">
							{/* prettier-ignore */}
							<label className="app-input__label" htmlFor="attachment">Attachment</label>
							<p className="apply-leave-modal__description">Please ensure that your file is in the correct format (jpg, pdf) with each file size not exceeding 5MB.</p>

							<div className="apply-leave-modal__input">
								{formik.values?.file && (
									<button type="button" className="apply-leave-modal__upload-button" onClick={onHandleUploadFile}>
										{formik.values?.file?.name}
										<img className="apply-leave-modal__icon" src={uploadIcon} alt="upload" />
									</button>
								)}

								{!formik.values?.file && (
									<button type="button" className="apply-leave-modal__upload-button" onClick={onHandleUploadFile}>
										Uploaded
										<img className="apply-leave-modal__icon" src={uploadIcon} alt="upload" />
									</button>
								)}

								<p className="apply-leave-modal__label">{converReadableFileSize(fileSize, true) || "-"}</p>

								<input type="file" name="file" accept="image/png, image/jpeg" hidden ref={uploadInputRef} onChange={onHandleChange} />
							</div>

							{formik.touched.file && formik.errors.file && <div className="apply-leave-modal__error">{formik.errors.file}</div>}
						</div>

						<div className="apply-leave-modal__button-container">
							<AppButton type="button" label="Cancel" outline onClick={onHandleDismiss} />
							<AppButton type="submit" label="Create" />
						</div>
					</form>
				</div>
			</div>
		</Modal>
	);
};

export default memo(forwardRef(AppApplyLeaveModal));

AppApplyLeaveModal.propTypes = {
	ref: PropTypes.object,
};
