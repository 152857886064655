import api from "services/api";
import serveRequestErrors from "common/serve-request-errors";

const getOrganisationListing = async () => {
	let response = null;
	let errorMessage = null;

	try {
		const res = await api.get.employee.organisation();
		response = { content: res.map(({ name, businessRegistrationNo, ...o }) => ({ ...o, label: name, value: businessRegistrationNo })) };
	} catch (error) {
		errorMessage = serveRequestErrors(error);
	}

	if (response) return response;

	throw errorMessage;
};

export default getOrganisationListing;
