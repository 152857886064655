import React, { memo, forwardRef, useImperativeHandle, useState, useCallback, useEffect, useMemo } from "react";
import Modal from "@mui/material/Modal";
import PropTypes from "prop-types";

import COMMON from "common";
import api from "services/api";
import { formatDatePattern } from "common/calendar";
import serveRequestErrors from "common/serve-request-errors";
import AppStatus from "components/app-status";
import AppCloseIcon from "components/icons/app-close-icon";
import pdfIcon from "assets/images/pages/leave-management/pdf-icon.svg";

export const AppLeaveModal = (props, ref) => {
	const [data, setData] = useState({});
	const [visible, setVisible] = useState(false);

	const productInfo = useMemo(() => {
		const companyLeaves = data?.companyLeaveApplicationDetails;
		const rejectReason = data?.status === COMMON.STATUS_ID.REJECTED ? { label: "Reject Reason", value: data?.rejectReason || "-" } : {};

		const info = [
			{ label: "Time Off", value: data.timeOff?.label || "-" },
			{ label: "Start Date", value: companyLeaves?.length ? formatDatePattern(companyLeaves.slice(0).shift().date) : "-" },
			{ label: "End Date", value: companyLeaves?.length ? formatDatePattern(companyLeaves.slice(0).pop().date) : "-" },
			{ label: "Days", value: data.noOfDays || "-" },
			{ label: "Submit Date", value: data.createdDate ? formatDatePattern(data.createdDate) : "-" },
			{ label: "Attachment", value: data?.companyLeaveApplicationFiles?.[0] || "-" },
			{ label: "Description", value: data.description || "-" },
			rejectReason && { ...rejectReason },
		];
		return info;
	}, [data]);

	const onHandleShow = useCallback((id) => {
		setData((prev) => ({ ...prev, id }));
		setVisible(true);
	}, []);

	const onHandleDismiss = useCallback(() => {
		setData({});
		setVisible(false);
	}, []);

	//prettier-ignore
	useImperativeHandle(ref, () => ({
		onHandleShow: onHandleShow,
		onHandleDismiss: onHandleDismiss,
	}));

	useEffect(() => {
		const onHandleGetDetails = async () => {
			let response = null;
			try {
				response = await api.get.leaves.leave(data.id);
			} catch (error) {
				serveRequestErrors(error);
			}
			if (response) {
				setData((prev) => ({ ...prev, ...response }));
			}
		};

		if (data.id) onHandleGetDetails();
	}, [data.id]);

	return (
		<Modal classes={{ root: "app-leave-modal" }} open={visible} onClose={onHandleDismiss} aria-labelledby="leave-modal" aria-describedby="leave-profile-modal">
			<div className="leave-modal">
				<div className="leave-modal__main">
					<div className="leave-modal__close-button" onClick={onHandleDismiss}>
						<AppCloseIcon color="#666666" />
					</div>

					<div className="leave-modal__header">
						<div className="leave-modal__profile">
							<p className="leave-modal__title">Leave Details</p>
						</div>
					</div>

					<div className="info">
						<div className="info__box">
							<p className="leave-modal__text">Leave Type</p>
							<p className="leave-modal__title">{data.leaveType}</p>
						</div>
						<div className="info__box">
							<p className="leave-modal__text">Status</p>
							<div className="leave-modal__title">
								<AppStatus status={data?.status || "-"} />
							</div>
						</div>
					</div>

					<div className="leave-modal__body">
						<div className="leave-modal__container">
							<div className="leave-modal__wrapper">
								{productInfo.map((o, i) => {
									const fileName = o.value?.fileName;

									return (
										<div className="leave-modal__info" key={i}>
											<p className="leave-modal__label">{o.label}</p>
											{!fileName && <p className="leave-modal__value">{o.value}</p>}
											{fileName && (
												<p className="leave-modal__value">
													<a href={o.value.fileURL} className="leave-modal__link" target="_blank" rel="noopener noreferrer">
														<img src={pdfIcon} alt="attachment" /> {fileName}
													</a>
												</p>
											)}
										</div>
									);
								})}
							</div>
						</div>
					</div>
				</div>
			</div>
		</Modal>
	);
};

export default memo(forwardRef(AppLeaveModal));

AppLeaveModal.propTypes = {
	ref: PropTypes.object,
};
