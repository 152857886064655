import React, { useCallback, useMemo, useEffect, useRef, useState } from "react";
import { useSearchParams } from "react-router-dom";

import COMMON from "common";
import api from "services/api";
import sanitizeObject from "common/sanitize-object";
import { formatDatePattern } from "common/calendar";
import serveRequestErrors from "common/serve-request-errors";
import { formatCurrency } from "common/format-currency-pattern";
import AppEmptyState from "components/app-empty-state";
import AppClaimModal from "components/pages/claim-management/app-claim-modal";
import AppTable, { AppTableCell, AppTableEditIcon } from "components/app-table";
import previewIcon from "assets/images/preview-icon.svg";
import pdfIcon from "assets/images/pages/leave-management/pdf-icon.svg";

const PageClaimPending = (props) => {
	const claimModalRef = useRef();
	const [searchParams, setSearchParams] = useSearchParams();
	const memoSetSearchParams = useRef(setSearchParams);
	const paramsRef = useRef({
		page: parseInt(searchParams.get("page")) || 1,
		sort: searchParams.get("sort") || "",
	});
	const [data, setData] = useState({ page: 0, size: 10, total: 0, offset: 0, totalElements: 0, prev: false, next: false, items: [] });
	const cancelRequest = useMemo(() => props.onHandleCancelRequest, [props.onHandleCancelRequest]);
	const isEmptyState = useMemo(() => COMMON.TABLE_REQUEST_STATUS.INSTANCE === data.status, [data.status]);

	const onHandleGetList = useCallback(async () => {
		let response = null;

		setData((prev) => ({ ...prev, items: [] }));

		try {
			const payload = { ...paramsRef.current, size: 10 };

			memoSetSearchParams.current(sanitizeObject(payload), { replace: true });

			payload.page = paramsRef.current.page - 1;

			response = await api.get.claims.history(sanitizeObject({ ...payload, statusListStr: "PENDING" }));
		} catch (error) {
			serveRequestErrors(error);
		}

		if (response) {
			setData((prev) => ({
				...prev,
				page: paramsRef.current.page,
				prev: !response.first,
				next: !response.last,
				items: response.content,
				total: response.totalPages,
				totalElements: response.totalElements,
				offset: response?.pageable?.offset || 0,
				status: response?.pageable,
			}));
		}
	}, []);

	const onHandleViews = useCallback((id) => {
		claimModalRef.current.onHandleShow(id);
	}, []);

	const AmountCell = useCallback(({ row }) => <AppTableCell right value={formatCurrency(row.original.claimValue)} />, []);

	const SubmitDate = useCallback(({ row }) => <AppTableCell center value={formatDatePattern(row.original.createdDate)} />, []);

	//prettier-ignore
	const AttachmentCell = useCallback(({ row }) => {
		const file = row.original?.companyClaimApplicationFiles?.[0];
		const value = file ? (<a href={file?.fileURL} target="_blank" rel="noopener noreferrer"><img className="table__file" src={pdfIcon} alt="attacment" /></a>) : ("-");
		return (
			<AppTableCell center value={value} />
		);
	}, []);

	//prettier-ignore
	const MenuViewCell = useCallback(({ row }) => {
		return <AppTableEditIcon icon={previewIcon} onClick={() => onHandleViews(row.original.id)} />; 
	}, [onHandleViews]);

	//prettier-ignore
	const columns = useMemo(() => [
		{
			Header: "Claim Type",
			accessor: "claimTypeMapped",
			disableSortBy: true,
		},
		{
			Header: "Submit Date",
			accessor: "createdDate",
			disableSortBy: true,
			Cell: SubmitDate,
		},
		{
			Header: () => <div className="table__amount">Amount (RM)</div>,
			accessor: "claimValue",
			disableSortBy: true,
			Cell: AmountCell
		},
        {
			Header: () => <div className="table__attachment">Attachment</div>,
			accessor: "companyClaimApplicationFiles",
			disableSortBy: true,
			Cell: AttachmentCell,
		},
        {
			Header: "",
			accessor: "*",
			disableSortBy: true,
			Cell: MenuViewCell,
		},
	], [SubmitDate, AmountCell, AttachmentCell, MenuViewCell]);

	useEffect(() => {
		onHandleGetList();
	}, [onHandleGetList]);

	useEffect(() => {
		return () => {
			cancelRequest(COMMON.ENDPOINT_PATH.CLAIMS.HISTORY);
		};
	}, [cancelRequest]);

	return (
		<div className="page-claim-pending">
			<div className="claim-pending">
				{isEmptyState && <AppEmptyState title="No records found" description="You don’t have any records yet" disabledButton />}
				{!isEmptyState && <AppTable columns={columns} pages={data} />}
			</div>

			<AppClaimModal ref={claimModalRef} />
		</div>
	);
};

export default PageClaimPending;
