import COMMON from "common";
import services from "services";
import encodeQueryParams from "common/encode-query-params";

const ignoreAuthHeader = { token: false };
const multipart = { headers: { "Content-Type": "multipart/form-data" } };

const api = {
	get: {
		general: {
			logo: () => services.get(COMMON.ENDPOINT_PATH.GENERAL.LOGO),
			company: () => services.get(COMMON.ENDPOINT_PATH.GENERAL.COMPANY),
			list: (params) => services.get(COMMON.ENDPOINT_PATH.GENERAL.LIST + encodeQueryParams(params)),
			states: (params) => services.get(COMMON.ENDPOINT_PATH.GENERAL.STATES + encodeQueryParams(params)),
			cities: (params) => services.get(COMMON.ENDPOINT_PATH.GENERAL.CITIES + encodeQueryParams(params)),
			postcode: (params) => services.get(COMMON.ENDPOINT_PATH.GENERAL.POSTCODE + encodeQueryParams(params)),
			companies: (params) => services.get(COMMON.ENDPOINT_PATH.GENERAL.COMPANIES + encodeQueryParams(params)),
			profile: () => services.get(COMMON.ENDPOINT_PATH.GENERAL.PROFILE, { cancelId: COMMON.ENDPOINT_PATH.GENERAL.PROFILE }),
			holiday: (params) => services.get(COMMON.ENDPOINT_PATH.GENERAL.HOLIDAY + encodeQueryParams(params), { cancelId: COMMON.ENDPOINT_PATH.GENERAL.HOLIDAY }),
			maintenance: () => services.get(COMMON.ENDPOINT_PATH.GENERAL.MAINTENANCE, { baseURL: process.env.REACT_APP_URL, cancelId: COMMON.ENDPOINT_PATH.GENERAL.MAINTENANCE }),
		},
		dashboard: {
			employee: () => services.get(COMMON.ENDPOINT_PATH.DASHBOARD.EMPLOYEE, { cancelId: COMMON.ENDPOINT_PATH.DASHBOARD.EMPLOYEE }),
		},
		leaves: {
			summary: () => services.get(COMMON.ENDPOINT_PATH.LEAVES.SUMMARY, { cancelId: COMMON.ENDPOINT_PATH.LEAVES.SUMMARY }),
			timeOff: () => services.get(COMMON.ENDPOINT_PATH.LEAVES.TIME_OFF, { cancelId: COMMON.ENDPOINT_PATH.LEAVES.TIME_OFF }),
			types: () => services.get(COMMON.ENDPOINT_PATH.LEAVES.LEAVE_TYPES, { cancelId: COMMON.ENDPOINT_PATH.LEAVES.LEAVE_TYPES }),
			list: (params) => services.get(COMMON.ENDPOINT_PATH.LEAVES.LIST + encodeQueryParams(params), { cancelId: COMMON.ENDPOINT_PATH.LEAVES.LIST }),
			leave: (params) => services.get(COMMON.ENDPOINT_PATH.LEAVES.LEAVE + encodeQueryParams(params), { cancelId: COMMON.ENDPOINT_PATH.LEAVES.LEAVE }),
			upcoming: (params) => services.get(COMMON.ENDPOINT_PATH.LEAVES.UPCOMING + encodeQueryParams(params), { cancelId: COMMON.ENDPOINT_PATH.LEAVES.UPCOMING }),
			calendar: (params) => services.get(COMMON.ENDPOINT_PATH.LEAVES.CALENDAR + encodeQueryParams(params), { cancelId: COMMON.ENDPOINT_PATH.LEAVES.CALENDAR }),
		},
		benefits: {
			list: (params) => services.get(COMMON.ENDPOINT_PATH.BENEFITS.LIST + encodeQueryParams(params), { cancelId: COMMON.ENDPOINT_PATH.BENEFITS.LIST }),
		},
		claims: {
			summary: () => services.get(COMMON.ENDPOINT_PATH.CLAIMS.SUMMARY, { cancelId: COMMON.ENDPOINT_PATH.CLAIMS.SUMMARY }),
			types: () => services.get(COMMON.ENDPOINT_PATH.LEAVES.LEAVE_TYPES, { cancelId: COMMON.ENDPOINT_PATH.CLAIMS.CLAIM_TYPES }),
			claim: (params) => services.get(COMMON.ENDPOINT_PATH.CLAIMS.CLAIM + encodeQueryParams(params), { cancelId: COMMON.ENDPOINT_PATH.CLAIMS.CLAIM }),
			history: (params) => services.get(COMMON.ENDPOINT_PATH.CLAIMS.HISTORY + encodeQueryParams(params), { cancelId: COMMON.ENDPOINT_PATH.CLAIMS.HISTORY }),
		},
		employee: {
			organisation: () => services.get(COMMON.ENDPOINT_PATH.EMPLOYEE.ORGANISATION),
			personalInfo: () => services.get(COMMON.ENDPOINT_PATH.EMPLOYEE.PERSONAL_INFO, { cancelId: COMMON.ENDPOINT_PATH.EMPLOYEE.PERSONAL_INFO }),
			employeeInfo: () => services.get(COMMON.ENDPOINT_PATH.EMPLOYEE.EMPLOYEE_INFO, { cancelId: COMMON.ENDPOINT_PATH.EMPLOYEE.EMPLOYEE_INFO }),
		},
	},
	post: {
		general: {
			refreshToken: (payload) => services.post(COMMON.ENDPOINT_PATH.GENERAL.REFRESH_TOKEN, payload, { ...ignoreAuthHeader }),
		},
		authenticate: {
			user: (params) => services.post(COMMON.ENDPOINT_PATH.AUTHENTICATE.USER + encodeQueryParams(params)),
			signIn: (payload) => services.post(COMMON.ENDPOINT_PATH.AUTHENTICATE.SIGN_IN, payload),
		},
		leaves: {
			create: (payload) => services.post(COMMON.ENDPOINT_PATH.LEAVES.CREATE, payload),
			cancel: (params) => services.post(COMMON.ENDPOINT_PATH.LEAVES.CANCEL + encodeQueryParams(params)),
			uploadDocument: (params, payload) => services.post(COMMON.ENDPOINT_PATH.LEAVES.UPLOAD_DOCUMENT + encodeQueryParams(params), payload, { ...multipart }),
		},
		claims: {
			create: (payload) => services.post(COMMON.ENDPOINT_PATH.CLAIMS.CREATE, payload),
			uploadDocument: (params, payload) => services.post(COMMON.ENDPOINT_PATH.CLAIMS.UPLOAD_DOCUMENT + encodeQueryParams(params), payload, { ...multipart }),
		},
		employee: {
			updatePersonalInfo: (payload) => services.post(COMMON.ENDPOINT_PATH.EMPLOYEE.UPDATE_PERSONAL_INFO, payload),
			updateEmployeeInfo: (payload) => services.post(COMMON.ENDPOINT_PATH.EMPLOYEE.UPDATE_EMPLOYEE_INFO, payload),
		},
	},
	patch: {},
	put: {},
};

export default api;
