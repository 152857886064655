import pathnames from "routes/pathnames";
import AppDashboardIcon from "components/icons/app-dashboard-icon";
import AppLeaveManagemetnIcon from "components/icons/app-leave-management-icon";
import AppClaimManagemetnIcon from "components/icons/app-claim-management-icon";

const sideNavList = [
	{
		label: "Dashboard",
		path: pathnames.dashboard,
		icon: AppDashboardIcon,
		childrens: [],
		relatives: [],
	},
	{
		label: "Leave Management",
		path: pathnames.leaves,
		icon: AppLeaveManagemetnIcon,
		relatives: [],
		childrens: [
			{
				label: "Leave Calendar",
				path: pathnames.leaves,
				relatives: [],
			},
			{
				label: "Leave Summary",
				path: pathnames.leavesSummary,
				relatives: [],
			},
			{
				label: "Upcoming Leave",
				path: pathnames.leavesUpcoming,
				relatives: [],
			},
			{
				label: "Pending Approval",
				path: pathnames.leavesPending,
				relatives: [],
			},
			{
				label: "Leave History",
				path: pathnames.leavesHistory,
				relatives: [],
			},
		],
	},
	{
		label: "Allowance Management",
		path: pathnames.leaves,
		icon: AppClaimManagemetnIcon,
		relatives: [],
		childrens: [
			{
				label: "Allowance Summary",
				path: pathnames.claimSummary,
				relatives: [],
			},
			{
				label: "Allowance History",
				path: pathnames.claimHistory,
				relatives: [],
			},
			{
				label: "Pending Claim",
				path: pathnames.claimPending,
				relatives: [],
			},
		],
	},
];

export default sideNavList;
