import { createSlice } from "@reduxjs/toolkit";

const initialState = {
	company: "",
	logo: "",
};

const profileSlice = createSlice({
	name: "profile",
	initialState,
	reducers: {
		setProfile(state, action) {
			return Object.assign(state, action.payload);
		},
		setLogo(state, action) {
			return Object.assign(state, { logo: action.payload });
		},
		setCompany(state, action) {
			return Object.assign(state, { company: action.payload });
		},
		resetProfile() {
			return initialState;
		},
	},
});

export const { setProfile, setCompany, setLogo, resetProfile } = profileSlice.actions;

export default profileSlice.reducer;
