import React, { memo } from "react";

const AppLeaveManagemetnIcon = ({ color = "#ffffff", ...props }) => {
	return (
		<svg {...props} className="leave-management-icon" width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
			<path
				d="M9.8125 7.04036C9.8125 6.9537 9.86583 6.86703 9.95917 6.80703C10.0525 6.74703 10.1792 6.70703 10.3125 6.70703L11.3125 6.70703C11.4458 6.70703 11.5725 6.74036 11.6658 6.80703C11.7592 6.86703 11.8125 6.9537 11.8125 7.04036L11.8125 7.70703C11.8125 7.7937 11.7592 7.88036 11.6658 7.94036C11.5725 8.00036 11.4458 8.04036 11.3125 8.04036L10.3125 8.04036C10.1792 8.04036 10.0525 8.00703 9.95917 7.94036C9.86583 7.88036 9.8125 7.7937 9.8125 7.70703L9.8125 7.04036Z"
				fill={color}
			/>
			<path
				d="M12.2254 3.14649L3.77203 3.14648C2.97674 3.14648 2.33203 3.79119 2.33203 4.58648L2.33203 12.1865C2.33203 12.9818 2.97674 13.6265 3.77203 13.6265L12.2254 13.6265C13.0207 13.6265 13.6654 12.9818 13.6654 12.1865L13.6654 4.58649C13.6654 3.7912 13.0207 3.14649 12.2254 3.14649Z"
				stroke={color}
			/>
			<path d="M2.54097 5.53956L13.561 5.53956L13.561 4.13289C13.561 4.13289 13.421 3.24622 12.601 3.21289L3.74763 3.21289C3.74763 3.21289 2.02763 3.29289 2.54097 5.53956Z" fill={color} />
			<path d="M4.64062 3.35971L4.64062 2.37305" stroke={color} strokeLinecap="round" />
			<path d="M11.3594 3.35971L11.3594 2.37305" stroke={color} strokeLinecap="round" />
		</svg>
	);
};

export default memo(AppLeaveManagemetnIcon);
