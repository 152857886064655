import api from "services/api";
import serveRequestErrors from "common/serve-request-errors";
import COMMON from "common";

const getLeaveTypesListing = async () => {
	let response = null;
	let errorMessage = null;

	try {
		const res = await api.get.leaves.types();

		if (res?.companyBenefitLeaves) {
			response = { content: res.companyBenefitLeaves.map(({ leaveTypeMapped, leaveType, ...o }) => ({ ...o, label: leaveTypeMapped, value: leaveType })).filter((o) => o.value !== COMMON.BENEFITS.LEAVES.CARRY_FORWARD_LEAVE) };
		}
	} catch (error) {
		errorMessage = serveRequestErrors(error);
	}

	if (response) return response;

	throw errorMessage;
};

export default getLeaveTypesListing;
