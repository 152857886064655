import React from "react";
import { compose } from "@reduxjs/toolkit";

import withAppIntegration from "contexts/with-app-integration";
import AppAlert from "components/app-alert";
import logo from "assets/images/logo.webp";

const PageApp = () => {
	return (
		<div className="page-app">
			<div className="app">
				<AppAlert />

				<main className="main">
					<div className="main__content">
						<img className="main__logo" src={logo} alt="logo" />
					</div>
					<p className="main__copyright">© {new Date().getFullYear()} Easycover.my. All Rights Reserved.</p>
				</main>
			</div>
		</div>
	);
};

export default compose(withAppIntegration)(PageApp);
