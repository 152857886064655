import React, { forwardRef, useCallback, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";

import COMMON from "common";
import api from "services/api";
import pathnames from "routes/pathnames";
import sanitizeError from "common/sanitize-error";
import { setCompany } from "store/slices/profile";

const withAppIntegration = (WrappedComponent) => {
	return forwardRef(({ ...props }, ref) => {
		const dispatch = useDispatch();
		const navigate = useNavigate();

		//prettier-ignore
		const onHandleRequestError = useCallback((error) => {
			const rnWebViewPostMessage = window?.postMessage;
	
			if(rnWebViewPostMessage) {
				rnWebViewPostMessage(JSON.stringify(error));
				rnWebViewPostMessage(JSON.stringify({ action: COMMON.EVENTS.FAILED, message: sanitizeError(error)}));
			}
		}, []);

		useEffect(() => {
			const rnWebViewPostMessage = window?.postMessage;
			const isUIWebView = () => navigator.userAgent.toLowerCase().match(/\(ip.*applewebkit(?!.*(version|crios))/);
			const receiver = isUIWebView() ? window : document;
			let counterLoaded = null;

			const onHandlePostMessage = async (message) => {
				try {
					let data = null;

					data = JSON.parse(message.data);

					if (data?.token && data.company) {
						sessionStorage.setItem(COMMON.AUTH_TOKEN, data.token);

						sessionStorage.setItem(COMMON.EVENTS.APP, COMMON.EVENTS.APP);

						await api.post.authenticate.user(data.company);

						document.querySelector("body").classList.add("ezc-app");

						if (rnWebViewPostMessage) rnWebViewPostMessage(JSON.stringify({ action: COMMON.EVENTS.SUCCESS }));
						else console.log(JSON.stringify({ action: COMMON.EVENTS.SUCCESS }));

						/* Get Current Company Details */
						const response = await api.get.general.company();

						if (response) dispatch(setCompany(response));

						navigate(pathnames.dashboard);
					}
				} catch (error) {
					onHandleRequestError(error);
				}
			};

			receiver.addEventListener("message", onHandlePostMessage);

			counterLoaded = setTimeout(() => {
				if (rnWebViewPostMessage) rnWebViewPostMessage(JSON.stringify({ action: COMMON.EVENTS.LOADED }));
				else console.log(JSON.stringify({ action: COMMON.EVENTS.LOADED }));
			}, 100);

			return () => {
				clearTimeout(counterLoaded);
				receiver.removeEventListener("message", onHandlePostMessage);
			};
		}, [dispatch, navigate, onHandleRequestError]);

		return <WrappedComponent {...props} ref={ref} />;
	});
};
export default withAppIntegration;
