import COMMON from "common";
import { getBetweenDates } from "common/calendar";
import capitalizeCharacter from "common/capitalize-character";

const validateLeaveBalance = (type, leaves, timeOff, startDate, endDate) => {
	let valid = false;
	const leaveType = capitalizeCharacter(type?.split("_")?.join(" "));
	const appliedLeaves = getBetweenDates(new Date(startDate), new Date(endDate));
	const balanceDays = leaves?.find((o) => o.leaveType === leaveType)?.balanceDays || 0;
	const timeOffValue = timeOff.options.find((o) => o.id.toString() === timeOff.id)?.unit !== 1 ? 2 : 1;

	switch (type) {
		case COMMON.BENEFITS.LEAVES.ANNUAL_LEAVE:
		case COMMON.BENEFITS.LEAVES.CARRY_FORWARD_LEAVE:
			const carryForward = capitalizeCharacter(COMMON.BENEFITS.LEAVES.CARRY_FORWARD_LEAVE.split("_").join(" "));
			const totalDays = (leaves?.find((o) => o.leaveType === carryForward)?.balanceDays || 0) + balanceDays;

			valid = appliedLeaves.length / timeOffValue <= totalDays;
			break;
		default:
			valid = appliedLeaves.length / timeOffValue <= balanceDays;
			break;
	}

	return valid;
};

export default validateLeaveBalance;
