import React, { useMemo, useEffect, useCallback, useRef, useContext } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { useFormik } from "formik";
import * as yup from "yup";

import COMMON from "common";
import api from "services/api";
import ERRORS from "common/errors";
import { CALENDAR_FORMAT } from "common/calendar";
import { promptAlertMessage } from "store/slices/alert";
import serveRequestErrors from "common/serve-request-errors";
import formatStringPattern from "common/format-string-pattern";
import formatPassportString from "common/format-passport-string";
import getBankListing from "services/get-bank-listing";
import getStateListing from "services/get-state-listing";
import getCitiesListing from "services/get-cities-listing";
import getPostcodeListing from "services/get-postcode-listing";
import getNationalityListing from "services/get-nationality-listing";
import getRelationshipListing from "services/get-relationship-listing";
import getMaritalStatusListing from "services/get-marital-status-listing";
import getIdentificationListing from "services/get-identification-listing";
import { AxiosContext } from "contexts/with-interceptor-provider";
import AppInput from "components/app-input";
import AppButton from "components/app-button";
import AppCheckbox from "components/app-checkbox";
import AppButtonInput from "components/app-button-input";
import AppMobileInput from "components/app-mobile-input";
import AppSelectInput from "components/app-select-input";
import AppMaskingInput from "components/app-masking-input";
import AppCalendarInput from "components/app-calendar-input";

const calendarDisplayFormat = CALENDAR_FORMAT.DATE_FORMAT + " " + CALENDAR_FORMAT.MONTH_FORMAT + " " + CALENDAR_FORMAT.YEAR_FORMAT;

const AppPersonalInfoTab = () => {
	const { id } = useParams();
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const bankRef = useRef();
	const context = useContext(AxiosContext);
	//prettier-ignore
	const initialValues = useMemo(() => ({
		name: "",
		nickname: "",
		idType: "",
		nric: "",
		passport: "",
		dateOfBirth: "",
		gender: "",
		nationality: "",
		maritalStatus: "",
		prefixNo: "",
		mobileNo: "",
		email: "",
		addressLine1: "",
		addressLine2: "",
		state: "",
		postcode: "",
		residentialAddress1: "",
		residentialAddress2: "",
		residentialState: "",
		residentialPostcode: "",
		permanentAddress: false,
		epfNo: "",
		incomeTaxNo: "",
		socsoNo: "",
		bank: "",
		bankAccountNo: "",
		swiftCode: "",
		emergencyContactsFullName: "",
		emergencyContactsRelationship: "",
		emergencyContactsMobileNo: "",
		emergencyContactsPrefixNo: "",
	}), []);
	const formik = useFormik({
		initialValues,
		validationSchema: yup.object({
			name: yup.string().required(ERRORS.REQUIRED),
			nickname: yup.string().required(ERRORS.REQUIRED),
			idType: yup.string().required(ERRORS.REQUIRED),
			passport: yup.string().when(["idType"], {
				is: (idType) => idType === COMMON.IDENTIFICATION_TYPE.PASSPORT,
				then: () => yup.string().required(ERRORS.REQUIRED),
			}),
			nric: yup.string().when(["idType"], {
				is: (idType) => idType === COMMON.IDENTIFICATION_TYPE.NRIC,
				then: () => yup.string().required(ERRORS.REQUIRED),
			}),
			dateOfBirth: yup.string().required(ERRORS.REQUIRED),
			gender: yup.string().required(ERRORS.REQUIRED),
			nationality: yup.string().required(ERRORS.REQUIRED),
			maritalStatus: yup.string().required(ERRORS.REQUIRED),
			mobileNo: yup.string().required(ERRORS.REQUIRED),
			email: yup.string().required(ERRORS.REQUIRED),
			addressLine1: yup.string().required(ERRORS.REQUIRED),
			state: yup.string().required(ERRORS.REQUIRED),
			postcode: yup.string().required(ERRORS.REQUIRED),
			emergencyContactsFullName: yup.string().required(ERRORS.REQUIRED),
			emergencyContactsRelationship: yup.string().required(ERRORS.REQUIRED),
			emergencyContactsMobileNo: yup.string().required(ERRORS.REQUIRED),
			residentialAddress1: yup.string().when(["permanentAddress"], {
				is: (permanentAddress) => !permanentAddress,
				then: () => yup.string().required(ERRORS.REQUIRED),
			}),
			residentialState: yup.string().when(["permanentAddress"], {
				is: (permanentAddress) => !permanentAddress,
				then: () => yup.string().required(ERRORS.REQUIRED),
			}),
			residentialRegion: yup.string().when(["permanentAddress"], {
				is: (permanentAddress) => !permanentAddress,
				then: () => yup.string().required(ERRORS.REQUIRED),
			}),
			residentialPostcode: yup.string().when(["permanentAddress"], {
				is: (permanentAddress) => !permanentAddress,
				then: () => yup.string().required(ERRORS.REQUIRED),
			}),
		}),
		onSubmit: (values) => {
			onHandleSubmit(values);
		},
	});
	const isPassport = useMemo(() => formik.values.idType === COMMON.IDENTIFICATION_TYPE.PASSPORT, [formik.values.idType]);
	const validIdentificationType = useMemo(() => !!formik.values.idType, [formik.values.idType]);
	const getCitiesLoadOptions = useCallback(() => getCitiesListing({ state: formik.values.state }), [formik.values.state]);
	const getPostcodeLoadOptions = useCallback(() => getPostcodeListing(`${formik.values.state}/${formik.values.region}`), [formik.values.state, formik.values.region]);
	const getResidentialCitiesLoadOptions = useCallback(() => getCitiesListing({ state: formik.values.residentialState }), [formik.values.residentialState]);
	const getResidentialPostcodeLoadOptions = useCallback(() => getPostcodeListing(`${formik.values.residentialState}/${formik.values.residentialRegion}`), [formik.values.residentialState, formik.values.residentialRegion]);
	const memoSetValues = useMemo(() => formik.setValues, [formik]);

	const onHandleSubmit = async (values) => {
		let response = null;

		try {
			let payload = {
				userId: id,
				general: {
					fullName: values.name,
					nickname: values.nickname,
					identificationType: values.idType,
					passport: values.idType === COMMON.IDENTIFICATION_TYPE.PASSPORT ? values.passport : "",
					nric: values.idType === COMMON.IDENTIFICATION_TYPE.NRIC ? values.nric?.split("-")?.join("") : "",
					dateOfBirth: values.dateOfBirth,
					gender: values.gender,
					nationality: values.nationality,
					maritalStatus: values.maritalStatus,
				},
				contactInfo: {
					mobileNoPrefix: values.prefixNo,
					mobileNo: values.mobileNo,
					email: values.email,
					correspondenceAddress1: values.addressLine1,
					correspondenceAddress2: values.addressLine2,
					correspondencePostcode: values.postcode,
					correspondenceState: values.state,
					correspondenceRegion: values.region,
					sameAsCorrespondenceAddress: values.permanentAddress ? "Y" : "N",
				},
				emergencyContact: {
					fullName: values.emergencyContactsFullName,
					relationship: values.emergencyContactsRelationship,
					mobileNoPrefix: values.emergencyContactsPrefixNo,
					mobileNo: values.emergencyContactsMobileNo,
				},
				bankDetail: {
					bankName: values.bank,
					bankNameDesc: bankRef.current.getOptionsData().find((o) => o.value === values.bank).label,
					bankAccount: values.bankAccountNo,
					swiftCode: values.swiftCode,
				},
				contribution: {
					epfNo: values.epfNo,
					incomeTaxNo: values.incomeTaxNo,
					socsoNo: values.socsoNo,
				},
			};

			if (!values.permanentAddress) {
				payload = {
					...payload,
					contactInfo: {
						...payload.contactInfo,
						residentialAddress1: values.residentialAddress1,
						residentialAddress2: values.residentialAddress2,
						residentialPostcode: values.residentialPostcode,
						residentialRegion: values.residentialRegion,
						residentialState: values.residentialState,
					},
				};
			}

			await api.post.employee.updatePersonalInfo(payload);

			response = true;
		} catch (error) {
			serveRequestErrors(error);
		} finally {
			formik.setSubmitting(false);
		}

		if (response) {
			dispatch(promptAlertMessage({ message: "Personal info has been successfully updated" }));
		}
	};

	const onHandleSelectPermanentAddress = (event) => {
		const name = event.target.name;
		const value = event.target.value;
		formik.setFieldValue(name, !value);
	};

	const onHandleCancel = () => {
		navigate(-1);
	};

	//prettier-ignore
	const onHandleSelectField = useCallback(async (event) => {
		const name = event.target.name;
		const value = event.target.value;

		switch (name) {
			case "state":
				formik.setFieldValue("region", "");
				formik.setFieldValue("postcode", "");
				break;
			case "region":
				formik.setFieldValue("postcode", "");
				break;
			case "postcode":
				break;
			case "residentialState":
				formik.setFieldValue("residentialRegion", "");
				formik.setFieldValue("residentialPostcode", "");
				break;
			case "residentialRegion":
				formik.setFieldValue("residentialPostcode", "");
				break;
			case "residentialPostcode":
				break;
			default:
				break;
		}

		formik.setFieldValue(name, value);
	}, [formik]);

	useEffect(() => {
		const onHandleGetDetails = async () => {
			let response = null;

			try {
				response = await api.get.employee.personalInfo();
			} catch (error) {
				serveRequestErrors(error);
			}

			if (response) {
				const bank = response?.bank;
				const general = response?.general;
				const contactInfo = response?.contactInfo;
				const contribution = response?.contribution;
				const emergencyContact = response?.emergencyContact;

				memoSetValues({
					name: general?.name,
					nickname: general?.nickname,
					idType: general?.identificationType,
					nric: general?.nric ? formatStringPattern(general?.nric, COMMON.MASKING.NRIC) : "",
					passport: general?.passport || "",
					dateOfBirth: general?.dateOfBirth,
					gender: general?.gender,
					nationality: general?.nationality || "",
					maritalStatus: general?.maritalStatus,
					mobileNo: contactInfo?.mobileNo,
					prefixNo: contactInfo?.mobileNoPrefix,
					email: contactInfo?.email,
					addressLine1: contactInfo?.correspondenceAddress1 || "",
					addressLine2: contactInfo?.correspondenceAddress2 || "",
					state: contactInfo?.correspondenceState || "",
					region: contactInfo?.correspondenceRegion || "",
					postcode: contactInfo?.correspondencePostcode || "",
					permanentAddress: contactInfo?.sameAsResidentialAddress === "Y",
					residentialAddress1: contactInfo?.residentialAddress1 || "",
					residentialAddress2: contactInfo?.residentialAddress2 || "",
					residentialRegion: contactInfo?.residentialRegion || "",
					residentialState: contactInfo?.residentialState || "",
					residentialPostcode: contactInfo?.residentialPostcode || "",
					bank: bank?.bankName || "",
					bankAccountNo: bank?.bankAccountNo || "",
					swiftCode: bank?.swiftCode || "",
					emergencyContactsFullName: emergencyContact?.fullName || "",
					emergencyContactsRelationship: emergencyContact?.relationship || "",
					emergencyContactsMobileNo: emergencyContact?.mobileNo || "",
					emergencyContactsPrefixNo: emergencyContact?.mobileNoPrefix || "",
					epfNo: contribution?.epfNo || "",
					incomeTaxNo: contribution?.incomeTaxNo || "",
					socsoNo: contribution?.socsoNo || "",
				});
			}
		};

		onHandleGetDetails();
	}, [memoSetValues]);

	useEffect(() => {
		return () => {
			context.onHandleCancelRequest(COMMON.ENDPOINT_PATH.EMPLOYEE.PERSONAL_INFO);
		};
	}, [context]);

	return (
		<div className="app-personal-info-tab">
			<form className="personal-info" onSubmit={formik.handleSubmit}>
				<div className="personal-info__box">
					<div className="personal-info__header">
						<p className="personal-info__title">General</p>
					</div>

					<div className="personal-info__form">
						{/* prettier-ignore */}
						<AppInput required disabled type="text" name="name" label="Full Name" placeholder="Full Name" value={formik.values.name} error={formik.errors.name} touched={formik.touched.name} onChange={formik.handleChange} />
						{/* prettier-ignore */}
						<AppInput required type="text" name="nickname" label="Nickname" placeholder="Nickname" disabled={formik.isSubmitting} value={formik.values.nickname} error={formik.errors.nickname} touched={formik.touched.nickname} onChange={formik.handleChange} />
						{/* prettier-ignore */}
						<AppSelectInput required disabled type="text" name="idType" label="Identification Type" placeholder="Please Select" loadOptions={getIdentificationListing} value={formik.values.idType} error={formik.errors.idType} touched={formik.touched.idType} onChange={formik.handleChange} />

						{
							/* prettier-ignore */ !isPassport && validIdentificationType && (<AppMaskingInput required disabled type="text" name="nric" label="NRIC No." placeholder="Placeholder" value={formik.values.nric} error={formik.errors.nric} touched={formik.touched.nric} onChange={formik.handleChange} format={COMMON.MASKING.NRIC} />)
						}

						{
							/* prettier-ignore */ isPassport && validIdentificationType && (<AppInput required disabled type="text" name="passport" label="Passport" placeholder="Passport" value={formik.values.passport} error={formik.errors.passport} touched={formik.touched.passport} onFormat={formatPassportString} onChange={formik.handleChange} />)
						}

						{/* prettier-ignore */}
						<AppCalendarInput required disabled name="dateOfBirth" label="Date of Birth" placeholder="01 Jan 2023" maxDate={new Date()} displayFormat={calendarDisplayFormat} value={formik.values.dateOfBirth} error={formik.errors.dateOfBirth} touched={formik.touched.dateOfBirth} onChange={formik.setFieldValue} />

						<AppButtonInput required disabled name="gender" label="Gender" value={formik.values.gender} error={formik.errors.gender} touched={formik.touched.gender} onChange={formik.setFieldValue} options={COMMON.DROPDOWNS.GENDER} />
						{/* prettier-ignore */}
						<AppSelectInput required disabled type="text" name="nationality" label="Nationality" placeholder="Please Select" loadOptions={getNationalityListing} value={formik.values.nationality} error={formik.errors.nationality} touched={formik.touched.nationality} onChange={formik.handleChange} />
						{/* prettier-ignore */}
						<AppSelectInput required disabled type="text" name="maritalStatus" label="Marital Status" placeholder="Please Select" loadOptions={getMaritalStatusListing} value={formik.values.maritalStatus} error={formik.errors.maritalStatus} touched={formik.touched.maritalStatus} onChange={formik.handleChange} />
					</div>
				</div>

				<div className="personal-info__box">
					<div className="personal-info__header">
						<p className="personal-info__title">Contact Info</p>
					</div>

					<div className="personal-info__form">
						{/* prettier-ignore */}
						<AppMobileInput required type="number" name="mobileNo" label="Mobile No." placeholder="Mobile No." value={formik.values.mobileNo} prefixNo={formik.values.prefixNo} error={formik.errors.mobileNo} touched={formik.touched.mobileNo} onChange={formik.handleChange} onChangeCode={formik.setFieldValue} disabled={formik.isSubmitting} />

						{/* prettier-ignore */}
						<AppInput required type="text" name="email" label="Personal Email" placeholder="Personal Email" value={formik.values.email} error={formik.errors.email} touched={formik.touched.email} onChange={formik.handleChange} disabled={formik.isSubmitting} />

						{/* prettier-ignore */}
						<AppInput required type="text" name="addressLine1" label="Residential Address" placeholder="Residential Address" value={formik.values.addressLine1} error={formik.errors.addressLine1} touched={formik.touched.addressLine1} onChange={formik.handleChange} disabled={formik.isSubmitting} />

						{/* prettier-ignore */}
						<AppInput type="text" name="addressLine2" label="Address (Line 2)" placeholder="Address (Line 2)" value={formik.values.addressLine2} error={formik.errors.addressLine2} touched={formik.touched.addressLine2} onChange={formik.handleChange} disabled={formik.isSubmitting} />

						{/* prettier-ignore */}
						<AppSelectInput required type="text" name="state" label="State" placeholder="Please Select" loadOptions={getStateListing} value={formik.values.state} error={formik.errors.state} touched={formik.touched.state} onChange={onHandleSelectField} disabled={formik.isSubmitting} />

						{/* prettier-ignore */}
						<AppSelectInput required type="text" name="region" label="Region" placeholder="Please Select" disabled={formik.isSubmitting || !formik.values.state} loadOptions={getCitiesLoadOptions} value={formik.values.region} error={formik.errors.region} touched={formik.touched.region} onChange={onHandleSelectField} />

						{/* prettier-ignore */}
						<AppSelectInput required type="text" name="postcode" label="Postcode" placeholder="Please Select" disabled={formik.isSubmitting || !formik.values.region} loadOptions={getPostcodeLoadOptions} value={formik.values.postcode} error={formik.errors.postcode} touched={formik.touched.postcode} onChange={onHandleSelectField} />
					</div>
				</div>
				<div className="personal-info__box">
					<div className="personal-info__header">
						<p className="personal-info__title">Permanent Address</p>
					</div>

					{!formik.values.permanentAddress && (
						<div className="personal-info__form">
							{/* prettier-ignore */}
							<AppInput required type="text" name="residentialAddress1" label="Residential Address" placeholder="Residential Address" disabled={formik.isSubmitting} value={formik.values.residentialAddress1} error={formik.errors.residentialAddress1} touched={formik.touched.residentialAddress1} onChange={formik.handleChange} />

							{/* prettier-ignore */}
							<AppInput type="text" name="residentialAddress2" label="Residential Address (Line 2)" placeholder="Address (Line 2)" disabled={formik.isSubmitting} value={formik.values.residentialAddress2} error={formik.errors.residentialAddress2} touched={formik.touched.residentialAddress2} onChange={formik.handleChange} />

							{/* prettier-ignore */}
							<AppSelectInput required type="text" name="residentialState" label="State" placeholder="Please Select" disabled={formik.isSubmitting} loadOptions={getStateListing} value={formik.values.residentialState} error={formik.errors.residentialState} touched={formik.touched.residentialState} onChange={onHandleSelectField} />

							{/* prettier-ignore */}
							<AppSelectInput required type="text" name="residentialRegion" label="Region" placeholder="Please Select" disabled={formik.isSubmitting || !formik.values.residentialState} loadOptions={getResidentialCitiesLoadOptions} value={formik.values.residentialRegion} error={formik.errors.residentialRegion} touched={formik.touched.residentialRegion} onChange={onHandleSelectField} />

							{/* prettier-ignore */}
							<AppSelectInput required type="text" name="residentialPostcode" label="Postcode" placeholder="Please Select" disabled={formik.isSubmitting || !formik.values.residentialRegion} loadOptions={getResidentialPostcodeLoadOptions} value={formik.values.residentialPostcode} error={formik.errors.residentialPostcode} touched={formik.touched.residentialPostcode} onChange={onHandleSelectField} />
						</div>
					)}

					<div className="personal-info__form">
						<div className="personal-info__checkbox">
							<AppCheckbox onClick={onHandleSelectPermanentAddress} name="permanentAddress" label="Same as Residential Address" disabled={formik.isSubmitting} value={formik.values.permanentAddress} />
						</div>
					</div>
				</div>

				<div className="personal-info__box">
					<div className="personal-info__header">
						<p className="personal-info__title">Emergency Contact</p>
					</div>

					<div className="personal-info__form">
						{/*prettier-ignore*/}
						<AppInput required type="text" name="emergencyContactsFullName" label="Full Name" placeholder="Full Name" disabled={formik.isSubmitting} value={formik.values.emergencyContactsFullName} error={formik.errors.emergencyContactsFullName} touched={formik.touched.emergencyContactsFullName} onChange={formik.handleChange} />

						{/* prettier-ignore */}
						<AppSelectInput required type="text"  name="emergencyContactsRelationship" label="Relationship" placeholder="Please Select" disabled={formik.isSubmitting} loadOptions={getRelationshipListing} value={formik.values.emergencyContactsRelationship} error={formik.errors.emergencyContactsRelationship} touched={formik.touched.emergencyContactsRelationship} onChange={formik.handleChange} />

						{/* prettier-ignore */}
						<AppMobileInput required type="number" name="emergencyContactsMobileNo" prefixNoName="emergencyContactsPrefixNo" label="Mobile No." disabled={formik.isSubmitting} placeholder="Mobile No." value={formik.values.emergencyContactsMobileNo} prefixNo={formik.values.emergencyContactsPrefixNo} error={formik.errors.emergencyContactsMobileNo} touched={formik.touched.emergencyContactsMobileNo} onChange={formik.handleChange} onChangeCode={formik.setFieldValue} />
					</div>
				</div>

				<div className="personal-info__box">
					<div className="personal-info__header">
						<p className="personal-info__title">Bank Details</p>
					</div>

					<div className="personal-info__form">
						{/* prettier-ignore */}
						<AppSelectInput disabled ref={bankRef} type="text" name="bank" label="Bank Name" placeholder="Please Select" loadOptions={getBankListing} value={formik.values.bank} error={formik.errors.bank} touched={formik.touched.bank} onChange={formik.handleChange} />

						{/* prettier-ignore */}
						<AppInput disabled type="number" name="bankAccountNo" label="Bank Account No." placeholder="Bank Account No." value={formik.values.bankAccountNo} error={formik.errors.bankAccountNo} touched={formik.touched.bankAccountNo} onChange={formik.handleChange} />

						{/* prettier-ignore */}
						<AppInput disabled type="text" name="swiftCode" label="Swift Code / Branch Name" placeholder="Swift Code / Branch Name" value={formik.values.swiftCode} error={formik.errors.swiftCode} touched={formik.touched.swiftCode} onChange={formik.handleChange} />
					</div>
				</div>

				<div className="personal-info__box">
					<div className="personal-info__header">
						<p className="personal-info__title">Contributions</p>
					</div>

					<div className="personal-info__form">
						{/* prettier-ignore */}
						<AppInput disabled type="text" name="epfNo" label="EPF No." placeholder="EPF No." value={formik.values.epfNo} error={formik.errors.epfNo} touched={formik.touched.epfNo} onChange={formik.handleChange} />

						{/* prettier-ignore */}
						<AppInput disabled type="text" name="incomeTaxNo" label="Income Tax No." placeholder="Income Tax No." value={formik.values.incomeTaxNo} error={formik.errors.incomeTaxNo} touched={formik.touched.incomeTaxNo} onChange={formik.handleChange} />

						{/* prettier-ignore */}
						<AppInput disabled type="text" name="socsoNo" label="Socso No." placeholder="Socso No." value={formik.values.socsoNo} error={formik.errors.socsoNo} touched={formik.touched.socsoNo} onChange={formik.handleChange} />
					</div>
				</div>

				<div className="personal-info__button-container">
					<AppButton type="button" label="Cancel" outline onClick={onHandleCancel} />
					<AppButton type="submit" label="Save" />
				</div>
			</form>
		</div>
	);
};

export default AppPersonalInfoTab;
