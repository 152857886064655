import React, { memo, forwardRef, useImperativeHandle, useState, useCallback, useContext, useMemo, Fragment } from "react";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import Modal from "@mui/material/Modal";
import PropTypes from "prop-types";

import api from "services/api";
import pathnames from "routes/pathnames";
import classNames from "common/class-names";
import serveRequestErrors from "common/serve-request-errors";
import { AxiosContext } from "contexts/with-interceptor-provider";
import { formatDatePattern } from "common/calendar";
import AppButton from "components/app-button";
import AppStatus from "components/app-status";
import AppCloseIcon from "components/icons/app-close-icon";
import editIcon from "assets/images/edit-icon.svg";
import COMMON from "common";

const TABS = [
	{ label: "Personal Info", value: "PERSONAL" },
	{ label: "Employee Info", value: "EMPLOYEE" },
];

export const AppProfileModal = (props, ref) => {
	const navigate = useNavigate();
	const context = useContext(AxiosContext);
	const profile = useSelector((state) => state.profile);
	const [data, setData] = useState({ personalInfo: {}, employeeInfo: {} });
	const [visible, setVisible] = useState(false);
	const [tab, setTab] = useState(TABS[0].value);

	//prettier-ignore
	const tabClassName = useCallback((currentTab) => {
		return classNames({
			"profile-modal__tab": true,
			"profile-modal__tab--active": tab === currentTab,
		});
	}, [tab]);

	const onHandleGetDetails = useCallback(async () => {
		let personalInfo = null;
		let employeeInfo = null;
		try {
			personalInfo = await api.get.employee.personalInfo();
			employeeInfo = await api.get.employee.employeeInfo();
		} catch (error) {
			serveRequestErrors(error);
		}
		if (personalInfo && employeeInfo) {
			setData((prev) => ({ ...prev, personalInfo, employeeInfo }));
		}
	}, []);

	const onHandleShow = useCallback(() => {
		onHandleGetDetails();
		setVisible(true);
	}, [onHandleGetDetails]);

	const onHandleDismiss = useCallback(() => {
		context.onHandleCancelRequest(COMMON.ENDPOINT_PATH.EMPLOYEE.EMPLOYEE_INFO);
		context.onHandleCancelRequest(COMMON.ENDPOINT_PATH.EMPLOYEE.PERSONAL_INFO);
		setData({ personalInfo: {}, employeeInfo: {} });
		setVisible(false);
	}, [context]);

	const onHandleTabChange = useCallback((index) => {
		setTab(index);
	}, []);

	//prettier-ignore
	const onHandleEditProfile = useCallback(() => {
		setVisible(false);
		navigate(pathnames.profile);
	}, [navigate]);

	//prettier-ignore
	useImperativeHandle(ref, () => ({
		onHandleShow: onHandleShow,
		onHandleDismiss: onHandleDismiss,
	}));

	const residentialAddress = useMemo(() => {
		let address = [];

		if (!data?.personalInfo?.contactInfo) return "";

		address.push(data.personalInfo?.contactInfo?.correspondenceAddress1);

		if (data.personalInfo?.contactInfo?.correspondenceAddress2) address.push(data.personalInfo?.contactInfo?.correspondenceAddress2);

		address.push(data.personalInfo?.contactInfo?.correspondencePostcode);

		address.push(data.personalInfo?.contactInfo?.correspondenceState);

		address.push(data.personalInfo?.contactInfo?.correspondenceRegion);

		return address.filter((o) => o).join(", ");
	}, [data?.personalInfo?.contactInfo]);

	const permenantAddress = useMemo(() => {
		let address = [];

		if (!data?.personalInfo?.contactInfo) return "";

		address.push(data.personalInfo?.contactInfo?.residentialAddress1);

		if (data.personalInfo?.contactInfo?.residentialAddress2) address.push(data.personalInfo?.contactInfo?.residentialAddress2);

		address.push(data.personalInfo?.contactInfo?.residentialPostcode);

		address.push(data.personalInfo?.contactInfo?.residentialRegion);

		address.push(data.personalInfo?.contactInfo?.residentialState);

		return address.filter((o) => o).join(", ");
	}, [data?.personalInfo?.contactInfo]);

	const tabsInfo = useMemo(() => {
		const info = {
			employee: {
				basic: {
					section: "",
					list: [
						{ label: "Organisation", value: data.employeeInfo?.organisationName },
						{ label: "Work Email", value: data.employeeInfo?.workEmail },
						{ label: "Position", value: data.employeeInfo?.position },
						{ label: "Level", value: data.employeeInfo?.level },
						{ label: "Contract Type", value: data.employeeInfo?.contractTypeDesc },
						{ label: "Date Joined", value: formatDatePattern(data.employeeInfo?.dateJoined) },
						{ label: "Probation End Date", value: formatDatePattern(data.employeeInfo?.probationEndDate) },
						{ label: "Latest Promotion Date", value: formatDatePattern(data.employeeInfo?.lastPromotionDate) },
						{ label: "Internship End Date", value: formatDatePattern(data.employeeInfo?.internshipEndDate) },
						{ label: "Reporting Manager", value: data.employeeInfo?.reportingManagerName },
					],
				},
				benefits: {
					section: "Benefits Enrollment",
					list: [
						{ label: "Benefit Plan", value: data.employeeInfo?.benefitPackageName },
						{ label: "Insurance Plan", value: data.employeeInfo?.ebInsurancePlanName },
					],
				},
			},
			personal: {
				basic: {
					section: "",
					list: [
						{ label: "Full Name", value: data.personalInfo?.general?.name },
						{ label: "Identification Type", value: data.personalInfo?.general?.identificationTypeDesc },
						{ label: "NRIC No.", value: data.personalInfo?.general?.nric },
						{ label: "Passport No.", value: data.personalInfo?.general?.passport },
						{ label: "Date of Birth", value: formatDatePattern(data.personalInfo?.general?.dateOfBirth) },
						{ label: "Gender", value: data.personalInfo?.general?.genderDesc },
						{ label: "Nationality", value: data.personalInfo?.general?.nationalityDesc },
						{ label: "Marital Status", value: data.personalInfo?.general?.maritalStatusDesc },
					],
				},
				contactInfo: {
					section: "Contact Info",
					list: [
						{ label: "Mobile No.", value: data.personalInfo?.contactInfo?.fullMobileNo },
						{ label: "Personal Email", value: data.personalInfo?.contactInfo?.email },
						{ label: "Residential Address", value: residentialAddress },
						{ label: "Permanent Address", value: data.personalInfo?.contactInfo?.sameAsResidentialAddress === "Y" ? "Same as Correspondence Address" : permenantAddress },
					],
				},
				emergencyContact: {
					section: "Emergency Contact",
					list: [
						{ label: "Full Name", value: data.personalInfo?.emergencyContact?.fullName },
						{ label: "Relationship", value: data.personalInfo?.emergencyContact?.relationshipDesc },
						{ label: "Mobile No.", value: data.personalInfo?.emergencyContact?.fullMobileNo },
					],
				},
				bankDetails: {
					section: "Bank Details",
					list: [
						{ label: "Bank Name", value: data.personalInfo?.bank?.bankNameDesc },
						{ label: "Bank Account No.", value: data.personalInfo?.bank?.bankAccountNo },
						{ label: "Swift Code / Branch Name", value: data.personalInfo?.bank?.swiftCode },
					],
				},
				contributions: {
					section: "Contributions",
					list: [
						{ label: "EPF No.", value: data.personalInfo?.contribution?.epfNo },
						{ label: "Income Tax No.", value: data.personalInfo?.contribution?.incomeTaxNo },
						{ label: "Socso No.", value: data.personalInfo?.contribution?.socsoNo },
					],
				},
			},
		};

		return info;
	}, [residentialAddress, permenantAddress, data?.employeeInfo, data?.personalInfo]);

	const Tabs = useCallback(() => {
		const type = tab.toLowerCase();
		const object = Object.keys(tabsInfo[type]);

		if (!object.length) return null;

		return (
			<div className="profile-modal__body">
				<div className="profile-modal__container">
					{object.map((a) => {
						const info = tabsInfo[type][a];

						return (
							<Fragment key={a}>
								{info.section && <p className="profile-modal__title">{info.section}</p>}
								<div className="profile-modal__wrapper">
									{info.list.map((b) => {
										return (
											<div className="profile-modal__info" key={b.label}>
												<p className="profile-modal__label">{b.label}</p>
												<p className="profile-modal__value">{b.value || "-"}</p>
											</div>
										);
									})}
								</div>
							</Fragment>
						);
					})}
				</div>
			</div>
		);
	}, [tabsInfo, tab]);

	return (
		<Modal classes={{ root: "app-profile-modal" }} open={visible} onClose={onHandleDismiss} aria-labelledby="profile-modal" aria-describedby="profile-modal">
			<div className="profile-modal">
				<div className="profile-modal__main">
					<div className="profile-modal__close-button" onClick={onHandleDismiss}>
						<AppCloseIcon color="#666666" />
					</div>

					<div className="profile-modal__header">
						<div className="profile-modal__image">
							<img src={profile.avatar} alt="profile" />
						</div>
						<div className="profile-modal__profile">
							<p className="profile-modal__title">{profile.fullName}</p>
						</div>

						<AppButton type="button" label="Edit" icon={editIcon} onClick={onHandleEditProfile} />
					</div>

					<div className="info">
						<div className="info__box">
							<p className="profile-modal__text">Employee ID</p>
							<p className="profile-modal__title">{data.employeeInfo?.employeeId}</p>
						</div>
						<div className="info__box">
							<p className="profile-modal__text">Status</p>
							<div className="profile-modal__title">
								<AppStatus status={data.employeeInfo?.employmentStatus} />
							</div>
						</div>
					</div>

					<div className="profile-modal__tabs">
						{TABS.map((o) => (
							<button key={o.value} className={tabClassName(o.value)} onClick={() => onHandleTabChange(o.value)}>
								{o.label}
							</button>
						))}
					</div>

					<Tabs />
				</div>
			</div>
		</Modal>
	);
};

export default memo(forwardRef(AppProfileModal));

AppProfileModal.propTypes = {
	ref: PropTypes.object,
};
