import api from "services/api";
import serveRequestErrors from "common/serve-request-errors";

const getCompanyListing = async () => {
	let response = null;
	let errorMessage = null;

	try {
		const res = await api.get.general.companies({ channel: "EMPLOYEE" });
		const content = res.map((o) => ({ label: o.name, value: o.businessRegistrationNo }));
		response = { content };
	} catch (error) {
		errorMessage = serveRequestErrors(error);
	}

	if (response) return response;

	throw errorMessage;
};

export default getCompanyListing;
