const COMMON = {
	REDUX_ACTION: {
		LOGOUT: "LOGOUT",
	},
	EVENTS: {
		APP: "APP",
		LOADED: "LOADED",
		FAILED: "FAILED",
		SUCCESS: "SUCCESS",
		LOGOUT: "LOGOUT",
	},
	REDUX_PRESIST_KEY: "root_hr_employee_6a46285d-e323-48ae-8a84-e1bc036f0223",
	AUTH_TOKEN: "easy_cover_hr_employee_@99e53932-f4d8-46fc-9bba-757fd5e32783_auth_tk",
	REFRESH_TOKEN: "easy_cover_hr_employee_@273f71f5-0d2d-45cd-b93b-fee2c84aea7b_refresh_tk",
	ENDPOINT_PATH: {
		GENERAL: {
			LOGO: "setting/logo",
			HOLIDAY: "general/holiday/",
			LIST: "general/lookup/detail",
			PROFILE: "user/profile/whoami",
			COMPANY: "user/profile/company/detail",
			COMPANIES: "user/profile/company/list",
			CITIES: "general/public/address/city/page",
			STATES: "general/public/address/state/page",
			POSTCODE: "general/public/address/postcode/",
			REFRESH_TOKEN: "authentication/public/token/refresh",
			MAINTENANCE: "service/config/config.json",
		},
		DASHBOARD: {
			EMPLOYEE: "dashboard/employee",
		},
		AUTHENTICATE: {
			USER: "user/profile/company/",
			SIGN_IN: "authentication/public/token/",
		},
		LEAVES: {
			CALENDAR: "leave/calendar/",
			TIME_OFF: "leave/time-off-types/",
			LIST: "leave/employee-leave/page",
			LEAVE: "leave/employee-leave/details/",
			CREATE: "leave/leave-application/apply",
			SUMMARY: "leave/employee-leave/summary",
			CANCEL: "leave/leave-application/cancel/",
			UPCOMING: "leave/employee-leave/upcoming/page",
			UPLOAD_DOCUMENT: "leave/leave-application/upload/",
			LEAVE_TYPES: "benefit/company-benefit-package/get-my-package",
		},
		BENEFITS: {
			LIST: "benefit/company-benefit-package/page",
		},
		CLAIMS: {
			HISTORY: "claim/employee-claim/page",
			SUMMARY: "claim/employee-claim/summary",
			CLAIM: "claim/employee-claim/details/",
			CREATE: "claim/claim-application/submit",
			UPLOAD_DOCUMENT: "claim/claim-application/upload/",
			CLAIM_TYPES: "benefit/company-benefit-package/get-my-package",
		},
		EMPLOYEE: {
			ORGANISATION: "employee/user-company",
			EMPLOYEE_INFO: "employee/detail/employee-info",
			PERSONAL_INFO: "employee/detail/personal-info",
			UPDATE_PERSONAL_INFO: "employee/update/personal-info",
			UPDATE_EMPLOYEE_INFO: "employee/update/employee-info",
		},
	},
	REGEX: {
		SYMBOL: /(\W|_)/,
		NUMERIC: /(?=.*\d)/,
		LOWERCASE: /(?=.*[a-z])/,
		UPPERCASE: /(?=.*[A-Z])/,
		EMAIL: /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
	},
	MASKING: {
		NRIC: "******-**-****",
	},
	IDENTIFICATION_TYPE: {
		NRIC: "NRIC",
		PASSPORT: "PASSPORT",
	},
	DROPDOWNS: {
		GENDER: [
			{ label: "Male", value: "MALE" },
			{ label: "Female", value: "FEMALE" },
		],
	},
	BENEFITS: {
		DAYS: {
			CALENDAR_DAYS: "CALENDAR_DAYS",
			WORKING_DAYS: "WORKING_DAYS",
		},
		LEAVES: {
			ANNUAL_LEAVE: "ANNUAL_LEAVE",
			MEDICAL_LEAVE: "MEDICAL_LEAVE",
			PATERNITY_LEAVE: "PATERNITY_LEAVE",
			MATERNITY_LEAVE: "MATERNITY_LEAVE",
			HOSPITALISATION_LEAVE: "HOSPITALISATION_LEAVE",
			CARRY_FORWARD_LEAVE: "CARRY_FORWARD_LEAVE",
		},
		CLAIMS: {
			MEDICAL_CLAIM: "MEDICAL_CLAIM",
			BODYCHECK_OPTICAL_DENTAL_CLAIM: "BODYCHECK_OPTICAL_DENTAL_CLAIM",
			OTHER_CLAIM: "OTHER_CLAIM",
		},
	},
	MAX_FILE_SIZE: 5000000,
	APP_STATUS: {
		NEW: "new",
	},
	STATUS_ID: {
		ACTIVE: "ACTIVE",
		INACTIVE: "INACTIVE",
		DELETED: "DELETED",
		DISABLED: "DISABLED",
		EXPIRED: "EXPIRED",
		PENDING: "PENDING",
		REJECTED: "REJECTED",
		SUCCEEDED: "SUCCEEDED",
		CANCELLED: "CANCELLED",
		ACCEPTED: "ACCEPTED",
		FAILED: "FAILED",
		READ: "READ",
		NOT_READ: "NOT_READ",
		WORKING: "WORKING",
		ON_LEAVE: "ON_LEAVE",
		APPROVED: "APPROVED",
		PROBATION: "PROBATION",
		INTERN: "INTERN",
		COMPLETED: "COMPLETED",
		PERMANENT: "PERMANENT",
		TERMINATION: "TERMINATION",
		RESIGNED: "RESIGNED",
		DISMISSAL: "DISMISSAL",
		CONVERSATION_ENDED: "CONVERSATION_ENDED",
		FORCE_CHANGE_PASSWORD: "FORCE_CHANGE_PASSWORD",
	},
	STATUS_CODE: {
		TOKEN_EXPIRED: "MC_000011",
		INVALID_TOKEN: "MC_000016",
		REFRESH_TOKEN_EXPIRED: "MC_000017",
		USER_BLOCKED: "MC_000018",
		MAXIMUM_PASSWORD_ATTEMPTS: "MC_UAA_00010",
	},
	TABLE_REQUEST_STATUS: {
		INSTANCE: "INSTANCE",
	},
};

export default COMMON;
