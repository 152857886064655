import api from "services/api";
import serveRequestErrors from "common/serve-request-errors";

const getTimeOffListing = async () => {
	let response = null;
	let errorMessage = null;

	try {
		const timeOff = await api.get.leaves.timeOff();
		response = { content: timeOff.map((o) => ({ ...o, value: o.id.toString(), unit: o.value })) };
	} catch (error) {
		errorMessage = serveRequestErrors(error);
	}

	if (response) return response;

	throw errorMessage;
};

export default getTimeOffListing;
