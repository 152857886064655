import React, { useCallback, useMemo, useEffect, useRef, useState } from "react";

import COMMON from "common";
import api from "services/api";
import serveRequestErrors from "common/serve-request-errors";
import { formatCurrency } from "common/format-currency-pattern";
import AppSearchInput from "components/app-search-input";
import AppTable, { AppTableCell } from "components/app-table";
import AppApplyClaimModal from "components/pages/claim-management/app-apply-claim-modal";
import addIcon from "assets/images/add-icon.svg";

const PageClaimSummary = (props) => {
	const applyClaimRef = useRef();
	const [data, setData] = useState({ page: 0, size: 10, total: 0, offset: 0, totalElements: 0, prev: false, next: false, items: [] });
	const cancelRequest = useMemo(() => props.onHandleCancelRequest, [props.onHandleCancelRequest]);

	const onHandleGetList = useCallback(async () => {
		let response = null;

		setData((prev) => ({ ...prev, items: [] }));

		try {
			response = await api.get.claims.summary();
		} catch (error) {
			serveRequestErrors(error);
		}

		if (response) {
			setData((prev) => ({ ...prev, items: response }));
		}
	}, []);

	const onHandleCreate = useCallback(() => {
		applyClaimRef.current.onHandleShow();
	}, []);

	const EntitlementAmountCell = useCallback(({ row }) => <AppTableCell center value={formatCurrency(row.original.entitlement)} />, []);

	const BalanceAmountCell = useCallback(({ row }) => <AppTableCell center value={formatCurrency(row.original.balance)} />, []);

	//prettier-ignore
	const columns = useMemo(() => [
		{
			Header: "Claim Type",
			accessor: "claimTypeMapped",
			disableSortBy: true,
		},
		{
			Header: "Entitled Amount (RM)",
			accessor: "entitlement",
			disableSortBy: true,
			Cell: EntitlementAmountCell,
		},
		{
			Header: "Balance Amount (RM)",
			accessor: "balance",
			disableSortBy: true,
			Cell: BalanceAmountCell
		},
	], [EntitlementAmountCell, BalanceAmountCell]);

	useEffect(() => {
		onHandleGetList();
	}, [onHandleGetList]);

	useEffect(() => {
		return () => {
			cancelRequest(COMMON.ENDPOINT_PATH.CLAIMS.SUMMARY);
		};
	}, [cancelRequest]);

	return (
		<div className="page-claim-summary">
			<div className="claim-summary">
				<AppSearchInput buttons={[{ label: "New Claim", icon: addIcon, onClick: onHandleCreate }]} />

				<AppTable columns={columns} pages={data} hideColumnSelect />
			</div>

			<AppApplyClaimModal ref={applyClaimRef} claimConfig={data.items} onHandleGetSummary={onHandleGetList} />
		</div>
	);
};

export default PageClaimSummary;
