import React, { useRef, useCallback, useMemo, useEffect, useState } from "react";

import COMMON from "common";
import api from "services/api";
import serveRequestErrors from "common/serve-request-errors";
import AppTable from "components/app-table";
import AppEmptyState from "components/app-empty-state";
import AppSearchInput from "components/app-search-input";
import AppApplyLeaveModal from "components/pages/leave-management/app-apply-leave-modal";
import leavesIcon from "assets/images/pages/leave-management/leave-icon.svg";

const PageLeavesSummary = (props) => {
	const applyLeaveRef = useRef();
	const [data, setData] = useState({ page: 0, size: 10, total: 0, offset: 0, totalElements: 0, prev: false, next: false, items: [] });
	const isEmptyState = useMemo(() => COMMON.TABLE_REQUEST_STATUS.INSTANCE === data.status, [data.status]);
	const cancelRequest = useMemo(() => props.onHandleCancelRequest, [props.onHandleCancelRequest]);

	const onHandleGetList = useCallback(async () => {
		let response = null;

		setData((prev) => ({ ...prev, items: [] }));

		try {
			response = await api.get.leaves.summary();
		} catch (error) {
			serveRequestErrors(error);
		}

		if (response) {
			setData((prev) => ({ ...prev, items: response }));
		}
	}, []);

	const onHandleCreate = useCallback(() => {
		applyLeaveRef.current.onHandleShow();
	}, []);

	//prettier-ignore
	const columns = useMemo(() => [
		{
			Header: "Leave Type",
			accessor: "leaveType",
			disableSortBy: true,
		},
		{
			Header: "Entitled Days",
			accessor: "entitledDays",
			disableSortBy: true,
		},
		{
			Header: "Balance Days",
			accessor: "balanceDays",
			disableSortBy: true,
		},
		{
			Header: "Days Taken",
			accessor: "daysTaken",
			disableSortBy: true,
		},
	], []);

	useEffect(() => {
		onHandleGetList();
	}, [onHandleGetList]);

	useEffect(() => {
		return () => {
			cancelRequest(COMMON.ENDPOINT_PATH.LEAVES.SUMMARY);
		};
	}, [cancelRequest]);

	return (
		<div className="page-leaves-summary">
			<div className="leaves-summary">
				<AppSearchInput buttons={[{ label: "New Leave", icon: leavesIcon, onClick: onHandleCreate }]} />

				{isEmptyState && <AppEmptyState title="No records found" description="You don’t have any records yet" disabledButton />}

				{!isEmptyState && <AppTable columns={columns} pages={data} />}

				<AppApplyLeaveModal ref={applyLeaveRef} leaveConfig={data.items} onHandleGetSummary={onHandleGetList} />
			</div>
		</div>
	);
};

export default PageLeavesSummary;
