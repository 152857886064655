import React, { memo, forwardRef, useImperativeHandle, useState, useCallback } from "react";
import Modal from "@mui/material/Modal";
import PropTypes from "prop-types";

import api from "services/api";
import serveRequestErrors from "common/serve-request-errors";
import AppButton from "components/app-button";

export const AppCancelLeaveModal = (props, ref) => {
	const [isSubmitting, setSubmitting] = useState();
	const [visible, setVisible] = useState(false);
	const [leaveId, setLeaveId] = useState({});

	const onHandleShow = useCallback((id) => {
		setLeaveId(id);
		setVisible(true);
	}, []);

	const onHandleDismiss = useCallback(() => {
		setVisible(false);
	}, []);

	const onHandleDelete = async () => {
		let response = null;
		setSubmitting(true);

		try {
			await api.post.leaves.cancel(leaveId);
			response = true;
		} catch (error) {
			serveRequestErrors(error);
		} finally {
			setSubmitting(false);
		}

		if (response) {
			onHandleDismiss();
			props.onHandleGetList();
		}
	};

	//prettier-ignore
	useImperativeHandle(ref, () => ({
		onHandleShow: onHandleShow,
		onHandleDismiss: onHandleDismiss,
	}));

	return (
		<Modal classes={{ root: "app-cancel-leave-modal" }} open={visible} onClose={onHandleDismiss} aria-labelledby="cancel-leave-modal" aria-describedby="cancel-leave-modal">
			<div className="cancel-leave-modal">
				<div className="cancel-leave-modal__main">
					<div className="cancel-leave-modal__header">
						<p className="cancel-leave-modal__title">Cancel Leave</p>
					</div>

					<div className="cancel-leave-modal__body">
						<p className="cancel-leave-modal__description">Are you sure want to cancel this leave?</p>

						<div className="cancel-leave-modal__button-container">
							<AppButton type="button" label="Cancel" outline onClick={onHandleDismiss} />
							<AppButton type="submit" label="Confirm" onClick={onHandleDelete} disabled={isSubmitting} />
						</div>
					</div>
				</div>
			</div>
		</Modal>
	);
};

export default memo(forwardRef(AppCancelLeaveModal));

AppCancelLeaveModal.propTypes = {
	ref: PropTypes.object,
};
