import React, { useCallback, useEffect, useMemo, useState, useRef } from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { debounce } from "lodash";

import COMMON from "common";
import api from "services/api";
import pathnames from "routes/pathnames";
import { formatDatePattern } from "common/calendar";
import serveRequestErrors from "common/serve-request-errors";
import AppTabs from "components/app-tabs";
import AppChevronIcon from "components/icons/app-chevron-icon";
import AppAnnouncementDescriptionModal from "components/pages/dashboard/app-announcement-description-modal";
import pdfIcon from "assets/images/pdf-icon.svg";
import holidayIcon from "assets/images/holiday-icon.svg";

const PageDashboard = (props) => {
	const announcementDescriptionRef = useRef();
	//prettier-ignore
	const tabsNav = useMemo(() => [
		{ label: "Posted", value: "Posted" },
	], []);
	const profile = useSelector((state) => state.profile);
	const [data, setData] = useState({});
	const [tab, setTab] = useState(tabsNav[0].value);
	const cancelRequest = useMemo(() => props.onHandleCancelRequest, [props.onHandleCancelRequest]);
	const today = useMemo(() => {
		const now = new Date();
		return `${now.toLocaleString("en-us", { weekday: "long" })}, ${formatDatePattern(now)}`;
	}, []);
	const greeting = useMemo(() => {
		const now = new Date();
		const currentHour = now.getHours();
		let text = "";

		if (currentHour < 12) {
			text = "good morning";
		} else if (currentHour < 18) {
			text = "good afternoon";
		} else {
			text = "good evening";
		}

		return `${profile?.fullName || "-"}, ${text}`;
	}, [profile]);

	const onHandleReadMoreDescription = useCallback((text) => {
		announcementDescriptionRef.current.onHandleShow(text);
	}, []);

	const Tabs = useCallback((obj) => {
		switch (obj.tab) {
			case "Posted":
				return (
					<ul className="announcements">
						{obj.announcements?.map((o) => {
							return (
								<li key={o.id} className="announcements__item">
									<div className="announcements__content">
										<p className="announcements__title">{o.title}</p>
										<p className="announcements__description">{o.description}</p>
										<p className="announcements__see-more" onClick={() => obj.onHandleReadMoreDescription(o.description)}>
											read more
										</p>
									</div>

									<div className="announcements__actions">
										{o.file && (
											<button type="button" className="announcements__button announcements__button--pdf">
												<a href={o.file} target="_blank" rel="noreferrer">
													<img src={pdfIcon} alt="attachement" />
												</a>
											</button>
										)}
									</div>
								</li>
							);
						})}
					</ul>
				);
			case "Upcoming":
				return null;
			default:
				return null;
		}
	}, []);

	const formatDate = (date) => {
		const today = new Date().getDate();
		const day = new Date(date).getDate();

		if (today === day) return "Today";

		if (day > 3 && day < 21) return day + "th";

		switch (day % 10) {
			case 1:
				return day + "st";
			case 2:
				return day + "nd";
			case 3:
				return day + "rd";
			default:
				return day + "th";
		}
	};

	useEffect(() => {
		const initialize = async () => {
			let response = null;

			try {
				response = await api.get.dashboard.employee();
			} catch (error) {
				serveRequestErrors(error);
			}

			if (response) setData(response);
		};

		initialize();
	}, []);

	useEffect(() => {
		return () => {
			cancelRequest(COMMON.ENDPOINT_PATH.DASHBOARD.EMPLOYEE);
		};
	}, [cancelRequest]);

	useEffect(() => {
		const multiLineOverflows = (element) => {
			if (!element) return false;

			return element.scrollHeight > element.clientHeight + 1;
		};

		const onHandleAnnouncementDescription = () => {
			/* Apply see more button if multiline overflows */
			const elements = document.querySelectorAll(".announcements__description");

			elements.forEach((o) => {
				const isMultiLineOverflows = multiLineOverflows(o);
				const nextElementSibling = o.nextElementSibling;
				const isValidSeeMoreButton = nextElementSibling.className === "announcements__see-more";

				if (!isValidSeeMoreButton) return;

				if (isMultiLineOverflows) {
					nextElementSibling.style.display = "block";
				} else {
					nextElementSibling.style.display = "none";
				}
			});
		};

		const debouncedCheck = debounce(onHandleAnnouncementDescription, 50);

		if (data.announcements) onHandleAnnouncementDescription();

		window.addEventListener("resize", debouncedCheck);

		return () => {
			window.removeEventListener("resize", debouncedCheck);
		};
	}, [data.announcements]);

	return (
		<div className="page-dashboard">
			<div className="dashboard">
				<h1 className="dashboard__headline">{greeting}</h1>
				<p className="dashboard__date">{today}</p>

				<div className="dashboard__section">
					<div className="card">
						<div className="card__header">
							<p className="card__title">Celebration Corner</p>
						</div>

						<ul className="celebrations">
							{data.birthdayEmployees?.map((o, i) => {
								return (
									<li key={i} className="celebrations__item celebrations__item--celebration">
										<div className="celebrations__profile" style={{ backgroundImage: `url(${o.avatar})` }} />
										<p className="celebrations__name">{o.name}</p>
										<p className="celebrations__date">{formatDate(o.date)}</p>
									</li>
								);
							})}
						</ul>
					</div>

					<div className="card">
						<div className="card__header">
							<p className="card__title">Holiday this Month</p>
						</div>

						<ul className="celebrations">
							{data.holidays?.map((o, i) => {
								return (
									<li key={i} className="celebrations__item">
										<img className="celebrations__icon" src={holidayIcon} alt="holiday" />
										<div className="celebrations__content">
											<p className="celebrations__name">{o.title}</p>
											{o.states && <p className="celebrations__state">{o.states}</p>}
										</div>
										<p className="celebrations__date">{formatDate(o.date)}</p>
									</li>
								);
							})}
						</ul>
					</div>

					<div className="card">
						<div className="card__header">
							<p className="card__title">Upcoming Leave</p>

							<Link className="card__link" to={pathnames.leavesUpcoming}>
								<div className="dashboard__icon">
									<p className="dashboard__label">View All</p>
									<AppChevronIcon color="#0245a9" />
								</div>
							</Link>
						</div>

						<ul className="leaves">
							{data.upcomingLeaves?.map((o, i) => {
								const onLeaveLength = o.companyLeaveApplicationDetails.length;
								const onLeaveStartDate = formatDate(o.companyLeaveApplicationDetails[0].date);
								const onLeaveEndDate = formatDate(o.companyLeaveApplicationDetails[onLeaveLength - 1].date);
								const dates = onLeaveLength > 1 ? `${onLeaveStartDate} - ${onLeaveEndDate}` : onLeaveStartDate;

								return (
									<li key={i} className="leaves__item">
										<p className="leaves__name">
											{o.leaveTypeMapped} - {o.timeOff.label}
										</p>
										<p className="leaves__balance">{dates}</p>
									</li>
								);
							})}
						</ul>
					</div>

					<div className="card">
						<div className="card__header">
							<p className="card__title">Leave Balance</p>

							<Link className="card__link" to={pathnames.leavesSummary}>
								<div className="dashboard__icon">
									<p className="dashboard__label">View All</p>
									<AppChevronIcon color="#0245a9" />
								</div>
							</Link>
						</div>

						<ul className="leaves">
							{data.leaveBalances?.map((o, i) => {
								return (
									<li key={i} className="leaves__item">
										<p className="leaves__name">{o.leaveTypeMapped}</p>
										<p className="leaves__balance">{o.balanceDays} days</p>
									</li>
								);
							})}
						</ul>
					</div>

					<div className="card">
						<div className="pendings">
							<div className="pendings__item">
								<div className="pendings__content">
									<p className="pendings__title">Pending Leave</p>
									<Link className="card__link" to={pathnames.leavesPending}>
										<div className="dashboard__icon">
											<p className="dashboard__label">View All</p>
											<AppChevronIcon color="#0245a9" />
										</div>
									</Link>
								</div>
								<div className="pendings__balance">{data.pendingLeaveCount || 0}</div>
							</div>

							<div className="pendings__divider" />

							<div className="pendings__item">
								<div className="pendings__content">
									<p className="pendings__title">Pending Claim</p>

									<Link className="card__link" to={pathnames.claimPending}>
										<div className="dashboard__icon">
											<p className="dashboard__label">View All</p>
											<AppChevronIcon color="#0245a9" />
										</div>
									</Link>
								</div>
								<div className="pendings__balance">{data.pendingClaimCount || 0}</div>
							</div>
						</div>
					</div>
				</div>

				<p className="dashboard__title">Announcements</p>

				<div className="dashboard__section">
					<AppTabs tabs={tabsNav} value={tab} onChange={setTab} />

					<Tabs tab={tab} announcements={data.announcements} onHandleReadMoreDescription={onHandleReadMoreDescription} />
				</div>
			</div>

			<AppAnnouncementDescriptionModal ref={announcementDescriptionRef} />
		</div>
	);
};

export default PageDashboard;
