import React, { useRef, useCallback, useMemo, useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";

import COMMON from "common";
import api from "services/api";
import { formatDatePattern, isDateBeforeToday } from "common/calendar";
import sanitizeObject from "common/sanitize-object";
import serveRequestErrors from "common/serve-request-errors";
import AppEmptyState from "components/app-empty-state";
import AppTable, { AppTableCell, AppTableEditIcon } from "components/app-table";
import AppCancelLeaveModal from "components/pages/leave-management/app-cancel-leave-modal";
import removeIcon from "assets/images/remove-icon.svg";

const PageLeavesUpcoming = (props) => {
	const cancelLeaveRef = useRef();
	const [searchParams, setSearchParams] = useSearchParams();
	const memoSetSearchParams = useRef(setSearchParams);
	//prettier-ignore
	const paramsRef = useRef({
		page: parseInt(searchParams.get("page")) || 1,
		sort: searchParams.get("sort") || "",
	});
	const [data, setData] = useState({ page: paramsRef.current.page, size: 10, total: 0, offset: 0, totalElements: 0, prev: false, next: false, items: [] });
	const isEmptyState = useMemo(() => COMMON.TABLE_REQUEST_STATUS.INSTANCE === data.status, [data.status]);
	const cancelRequest = useMemo(() => props.onHandleCancelRequest, [props.onHandleCancelRequest]);

	const onHandleGetList = useCallback(async () => {
		let response = null;

		setData({ page: paramsRef.current.page, size: 10, total: 0, offset: 0, totalElements: 0, prev: false, next: false, items: [] });

		try {
			const payload = { ...paramsRef.current, size: 10 };

			memoSetSearchParams.current(sanitizeObject(payload), { replace: true });

			payload.page = paramsRef.current.page - 1;

			response = await api.get.leaves.upcoming(sanitizeObject(payload));
		} catch (error) {
			serveRequestErrors(error);
		}

		if (response) {
			setData((prev) => ({
				...prev,
				page: paramsRef.current.page,
				prev: !response.first,
				next: !response.last,
				items: response.content,
				total: response.totalPages,
				totalElements: response.totalElements,
				offset: response?.pageable?.offset || 0,
				status: response?.pageable,
			}));
		}
	}, []);

	//prettier-ignore
	const onHandlePagination = useCallback((event) => {
		const control = event.currentTarget?.getAttribute("data-ctrl");

		if (control) {
			if (control === "prev") {
				if (paramsRef.current.page <= 1) return;
				paramsRef.current.page -= 1;
			} else {
				if (paramsRef.current.page >= data.total) return;
				paramsRef.current.page += 1;
			}
		} else {
			paramsRef.current.page = event.target.value;
		}

		onHandleGetList();
	}, [onHandleGetList, data.total]);

	//prettier-ignore
	const onHandleSort = useCallback((id, order) => {
		switch (id) {
			case "name":
				paramsRef.current.sort = order ? id + "," + order : "";
				break;
			default:
				break;
		}

		paramsRef.current.page = 1;

		onHandleGetList();
	}, [onHandleGetList]);

	//prettier-ignore
	const onHandleEdit = useCallback((id) => {
		cancelLeaveRef.current.onHandleShow(id);
	}, []);

	//prettier-ignore
	const MenuCell = useCallback(({ row }) => {
		const isDisabled = row.original.companyLeaveApplicationDetails.some(o => isDateBeforeToday(o.date, new Date()))
		return !isDisabled ? <AppTableEditIcon onClick={(event) => onHandleEdit(row.original.id)} icon={removeIcon} /> : null;
	}, [onHandleEdit]);

	const StartDateCell = useCallback(({ row }) => {
		return <AppTableCell left value={formatDatePattern(row.original.companyLeaveApplicationDetails[0].date) || "-"} />;
	}, []);

	const EndDateCell = useCallback(({ row }) => {
		return <AppTableCell left value={formatDatePattern(row?.original?.companyLeaveApplicationDetails?.[row?.original?.companyLeaveApplicationDetails?.length - 1]?.date) || "-"} />;
	}, []);

	//prettier-ignore
	const columns = useMemo(() => [
		{
			Header: "Leave Type",
			accessor: "leaveType",
			disableSortBy: true,
		},
		{
			Header: "Days",
			accessor: "noOfDays",
			disableSortBy: true,
		},
		{
			Header: "Start Date",
			id: "startDate",
			accessor: "companyLeaveApplicationDetails",
			disableSortBy: true,
			Cell: StartDateCell
		},
		{
			Header: "End Date",
			id: "endDate",
			accessor: "companyLeaveApplicationDetails",
			disableSortBy: true,
			Cell: EndDateCell
			
		},
		{
			Header: "Time Off",
			accessor: "timeOff.label",
			disableSortBy: true,
		},
		{
			Header: "",
			accessor: "*",
			disableSortBy: true,
			Cell: MenuCell,
		},
	], [MenuCell, StartDateCell, EndDateCell]);

	useEffect(() => {
		onHandleGetList();
	}, [onHandleGetList]);

	useEffect(() => {
		return () => {
			cancelRequest(COMMON.ENDPOINT_PATH.LEAVES.UPCOMING);
		};
	}, [cancelRequest]);

	return (
		<div className="page-leaves-upcoming">
			<div className="leaves-upcoming">
				{isEmptyState && <AppEmptyState title="No records found" description="You don’t have any records yet" disabledButton />}

				{!isEmptyState && <AppTable columns={columns} pages={data} onHandlePagination={onHandlePagination} onHandleSort={onHandleSort} />}
			</div>

			<AppCancelLeaveModal ref={cancelLeaveRef} onHandleGetList={onHandleGetList} />
		</div>
	);
};

export default PageLeavesUpcoming;
