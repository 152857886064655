import React, { memo, useRef, useState, useCallback } from "react";
import { compose } from "@reduxjs/toolkit";

import withProfile from "contexts/with-profile";
import withCompanies from "contexts/with-companies";
import AppAlert from "components/app-alert";
import AppHeader from "components/app-header";
import AppSideNav from "components/app-side-nav";

const AppLayout = ({ children, ...props }) => {
	const sideNavRef = useRef();
	const [title, setTitle] = useState("");
	const [backTitle, setBackTitle] = useState("");

	const onHandleSetTitle = useCallback((text) => {
		setTitle(text);
	}, []);

	const onHandleSetBackTitle = useCallback((text) => {
		setBackTitle(text);
	}, []);

	const onHandleToggleSideNav = useCallback(() => {
		sideNavRef.current.onHandleToggleSideNav();
	}, []);

	return (
		<div className="app-layout">
			<AppAlert />

			<main className="main">
				<AppSideNav ref={sideNavRef} onHandleSetTitle={onHandleSetTitle} onHandleSetBackTitle={onHandleSetBackTitle} />

				<div id="layout-container" className="main__container">
					<AppHeader title={title} backTitle={backTitle} onHandleToggleSideNav={onHandleToggleSideNav} />

					<div className="main__page">{props.initialized && React.cloneElement(children, { ...props })}</div>

					<footer className="main__copyright">© {new Date().getFullYear()} Easycover.my. All Rights Reserved.</footer>
				</div>
			</main>
		</div>
	);
};

export default compose(memo, withProfile, withCompanies)(AppLayout);
